import React from 'react'

const CheckEmailPage = () => {
  return (
    <div>
        <h1>請確認電子郵件是否收到認證信</h1>
        <p>若無收到,請確認註冊信箱是否正確,亦是確認有無歸類為垃圾郵件</p>
    </div>
  )
}

export default CheckEmailPage