import React, { useEffect, useRef, useState } from 'react'
import chat from "../images/design_after/chat.svg";
import { useUserAuth } from '../context/UserAuthContext';
import "./style/chatbox.css";
import avatar from "../images/design_after/icon_userdefault.svg";
import icon_arrow from "../images/design_after/icon_arrow.svg";
import { db } from '../config/firebase';
import { collection, query, where, doc, onSnapshot, updateDoc, getDoc ,getDocs, Timestamp, arrayUnion } from 'firebase/firestore';

const Chatbox = () => {
    const { user } = useUserAuth();
    const [isopen, setOpen] = useState();
    const [isopenChatroom, setOpenChatroom] = useState();

    const [chatroomName, setChatroomName] = useState(null);
    const [chatroomID, setChatroomID] = useState(null);
    const [chatConversation, setChatConversation] = useState(null);
    const [userChatData, setUserChatData] = useState(null);
    useEffect(() => {
        const fetch = async () => {
            const chatRef = collection(db, "chat");
            const q = query(chatRef, where("memberID", "array-contains", user.id));

            const querySnapshot = await getDocs(q);
            return querySnapshot;
        }
        fetch()
        .then((value) => {
            setUserChatData(value);
        })
        .catch((err) => {
            setUserChatData(null);
        })
    },[user.id])

    const Contacts = (props) => {
        const ChatData = props.data;
        const array = [];
        const [last, setLase] = useState([]);
        const [unreadNum, setUnreadNum] = useState([]);
        useEffect(() => {
            const chatRef = collection(db, "chat");
            const q = query(chatRef, where("memberID", "array-contains", user.id));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const lasewords = [];
                const read = [];
                querySnapshot.forEach((doc) => {
                    lasewords.push(doc.data().conversation.slice(-1)[0].text);
                    const isRead = doc.data().conversation.findIndex((i) => i.read == false);
                    console.log(isRead);
                    const unread = isRead === -1 ? 0 : doc.data().conversation.length - isRead;
                    console.log(unread);
                    read.push(unread)
                });
                setLase(lasewords);
                setUnreadNum(read);
              });
            return() => {
                unsubscribe()
            }
        },[])

        ChatData.forEach((doc) => {
            const allmember = doc.data();
            const talktoOthers = allmember.memberName.filter(function(value){
                return value !== user.username 
            })
            array.push({
                chatroomID: doc.id,
                talkTo: talktoOthers[0],
                conversation: doc.data().conversation,
            })
        });

        const chats = array.map((doc, index, arr) => {

            // const isRead = doc.conversation.findIndex((i) => i.read == false);
            // const unread = doc.conversation.length - isRead;
            const lastsentence = doc.conversation.slice(-1)[0]?.text;
            return(
            <div key={index}>  
                <li onClick={() => {
                    setChatroomName(doc.talkTo);
                    setChatroomID(doc.chatroomID);
                    setChatConversation(doc.conversation);
                    setOpenChatroom(true);
                }}>
                    <div className="chatroom_cube">
                        <div className='d-flex'>
                            <img className="chatroom_avatar" src={avatar} width="48px" height="48px"/>
                            <div className="chatroom_lastsentence">
                                <a>{doc.talkTo}</a>
                                <a>{last ? last[index] : lastsentence}</a>
                            </div>
                        </div>
                        
                        {unreadNum[index] > 0 && <div className="chatroom_number"><p style={{color: "#FFFF"}}>{unreadNum[index]}</p></div>}
                    </div>
                </li>
                <hr/>
            </div>
            )

        })
        return <div className="chatbody">{chats.length === 0 ? <p>您尚無任何聯絡人</p> : chats}</div>
    }
    const ChatHeader = (props) => {
        return (
        <div className="chatheader">
            <img onClick={() => {setOpenChatroom(false);}} src={icon_arrow}/>
            {props.chatroom && <h3>{props.chatroom}</h3>} 
        </div>
        )
    }
    const Conversation = (props) => {
        const chatID = props.roomID;
        const [chat, setChat]= useState([]); 
        
        useEffect(() => {
            const unSub = onSnapshot(
                doc(db, "chat", chatID),
                async (doc) => {
                doc.exists() && 
                    setChat(doc.data().conversation);
                    props.setChange(true);
                    console.log("ischating");
            });
            // mistake
            const letRead = async () => {                 
                const docRef = doc(db, "chat", chatID);
                const docSnap = await getDoc(docRef);
                if(docSnap.exists()){
                    const conversationArr = docSnap.data().conversation;
                    conversationArr.forEach((obj) => obj.read = true);
                    await updateDoc(docRef , {
                        conversation: conversationArr
                    });
                    
                }
            } 

            console.log(123);
            return () => {
              unSub();
              letRead();
            };  
        }, [chatID]);

          return(
            <div className = "conversation">
                {chat.map((doc, i) => (
                    <ConversationBox message={doc} key={i}/>
                ))}
            </div>
          )
    }
    const ConversationBox = (props) => {
        const doc = props.message;
        const ref = useRef();
        useEffect(() => {
          ref.current?.scrollIntoView({ behavior: "smooth" });

        }, [doc]);
        const date = new Date(doc.Timestamp.seconds*1000);
        if(doc.By === user.username){
            return(
                <div className="sentence myself" ref={ref}>
                    <span>{date.getHours()}:{date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()}</span>
                    <li>
                        <a>{doc.text}</a>
                    </li>
                </div>
            )
        }else{
            return(
                <div className="sentence others" ref={ref}>
                    <li>
                        <a>{doc.text}</a>
                    </li>
                    <span>{date.getHours()}:{date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()}</span>
                </div>
            )
        }
        
    }
    const Input = (props) => {
        const chatID = props.roomID;
        const [text, setText] = useState("");
        const [img, setImg] = useState(null);
        const handleSend = async () => {
            if(img){
                // const storageRef = ref(storage);
                // const uploadTask = uploadBytesResumable(storageRef, img);
                // uploadTask.on(
                //   (error) => {
                //     //TODO:Handle Error  
                //   },
                //   () => {
                //     getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                //       await updateDoc(doc(db, "chat", chatID), {
                //         conversation: arrayUnion({
                //           text,
                //           By: user.name,
                //           Timestamp: Timestamp.now(),
                //           img: downloadURL,
                //         }),
                //       });
                //     });
                //   }
                // );
            }else{
                await updateDoc(doc(db, "chat", chatID), {
                    conversation: arrayUnion({
                        img: "",
                        text: text, 
                        By: user.username,
                        read: false,
                        Timestamp: Timestamp.now(),
                    })
                })
            }
            setImg(null);
            setText("");
        }
        const handleClickEnter = (e) => {
            if(e.keyCode == 13 && text !== ""){
                handleSend();
            }else{
              return false;
            }
          };
        return(
        <div className="">
            <input type="text" id="chatInput" placeholder="輸入訊息" onKeyDown={handleClickEnter} onChange={(e) => {
                setText(e.target.value)
              }} value={text}/>
            {/* <input type="file" id="chatFile" onChange={(e) => {setImg(e.target.files[0])}}/> */}
            <button hidden onClick={handleSend}>傳送</button>
        </div> 
        )
    }
    const Message = () => {
        const [isChange, setChange] = useState(false);
        console.log(isChange);
        // useEffect(() =>{
        //     const q = query(collection(db, "chat"), where("memberID", "array-contains", user.id));
        //     const unsubscribe = onSnapshot(q, (snapshot) => {
        //         snapshot.docChanges().forEach((change) => {
        //             if (change.type === "modified") {
        //                 setChange(true)
        //             }
        //         });
        //     });
        //     console.log("test");
        //     unsubscribe()
        // },[])
        return(
            <div className="chatroom" >
                {isopenChatroom ? 
                    <div className="message">
                        <div className="messageHead">
                            <ChatHeader chatroom={chatroomName}/>
                        </div>
                        <div className="messageContent">
                            <Conversation
                                roomID={chatroomID} 
                                conversation={chatConversation}
                                setChange={setChange}
                            />
                        </div>
                        <div className="messageInput">
                            <Input roomID={chatroomID}/>       
                        </div>
                    </div>
                    :
                    <div className="contacts" >
                        <div className="chathead"><h2>聊天室</h2></div>
                        <Contacts data={userChatData}/>
                    </div>
                }
            </div>
        )

    }

    return (
    <div id='chatbox'>
        {isopen && <Message/>}
        {user.email && 
          <>
            <img id='icon' src={chat} height="95px" width="95x" onClick={() => {setOpen(!isopen);}}/>
            {/* {isChange && <span class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                <span class="visually-hidden">New alerts</span>
            </span>} */}
          </>
        }
    </div>
    )
}

export default Chatbox;