import React, { useEffect, useState } from 'react';
import EmergencyComponent from '../component/emergency_component.js';
import SearchBar from '../component/searchbar';
import NormalComponent from '../component/normal_component';
import ad from "../images/design_after/Group2.png";
import exclamation_mark from "../images/design_after/exclamation_mark.svg";
import "../component/style/match.css";
import { db } from '../config/firebase';
import { collection, getDocs } from "firebase/firestore";

const MatchingPage = () => {
    let [dialog, setDialog] = useState(true);
    let [normalMission, setNormalMission] = useState([]);
    let [repeater, setRepeater] = useState();
    useEffect(() => {
        Test123()
    },[])
    const Test123 = async () => {
        let arrF = [];
        const querySnapshot = await getDocs(collection(db, "mission"));
        querySnapshot.forEach((doc) => {
            const today = new Date();
            const down = new Date(doc.data().take_down.seconds*1000);
            if(doc.data().level !== "緊急任務(試用期免費 上架24小時)" && today < down){
                const arrS = [
                    doc.data().category,//[0]
                    doc.data().title,//[1] 
                    doc.data().hourlyRate,//[2]
                    doc.data().location,//[3]
                    doc.data().description,//[4] //
                    doc.data().duration?.start,//[5]
                    doc.data().duration?.end,//[6]
                    doc.data().poster_name,//[7]
                    doc.data().remark,//[8] 
                    doc.data().file,//[9]
                    doc.id,//[10]
                    doc.data().take_down,//[11]
                    doc.data().period,//[12]
                    doc.data().week,//[13]
                    doc.data().level,//[14]
                    doc.data().applicant,//[15]
                    doc.data().salaryMethod,//[16]
                    doc.data().pieceRate,//[17]
                    doc.data().profession,//[18]
                ]
                arrF.push(arrS)
            };
        });
        setNormalMission(arrF);
    }
    const transData = (data) => {
        setRepeater(data)
    }

  return(
    <>
        <div className='dialog' onClick={() => {setDialog(false)}}>
            <dialog open={dialog} >
                <img src={ad} alt='advertisement' />
            </dialog>
        </div>
        <div className="emergency_background">
            <div className="emergency_head">
            <div className="emergency_title">
                <img src={exclamation_mark}/><img src={exclamation_mark}/><span>緊 急 任 務</span><img src={exclamation_mark}/><img src={exclamation_mark}/>
            </div>
            <div className="emergency_subtitle"><p>限時高額報酬等你大展身手</p></div>
            </div>
            <EmergencyComponent/>
        </div>
        <SearchBar mission ={normalMission} funcs={transData}/>
        <NormalComponent init={normalMission} searchresult ={repeater}/>
    </>
  )
  }

export default MatchingPage;