import { useState, useEffect } from 'react';

const useRWD = () => {
    const [device,setDevice]=useState("mobile");

    const handleRWD=()=>{
        if(window.innerWidth>991)
            setDevice("pc");
        else if (window.innerWidth>414)
            setDevice("ipad");
        else
            setDevice("mobile");
    }

    useEffect(()=>{ 
        window.addEventListener('resize',handleRWD);
        handleRWD();
        return(()=>{
            window.removeEventListener('resize',handleRWD);
        })
    },[]);

    return device;
}

export default useRWD