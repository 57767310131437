import React, { useState } from 'react'
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import icon_search from "../images/design_after/icon_search.svg";
import "./style/searchbar.css";
import { Outlet } from 'react-router-dom';

const SearchBar = (props) => {
  let [ keyword, setKeyword ] = useState("");
  let [ region, setRegion ] = useState("");
  let [ category, setCategory ] = useState("");
  
  const alldata = props.mission;

  const searchMission = () => {
    const fakeArr = [];
    //description
    const listItem = alldata.map((number, i) => {
      const inputKeyword = [number[1], number[4], number[8]];
      const inputRegion = number[3].slice(0, 3);
      const inputCategory = number[0];
      // const a = keyword.trim();
      const result = inputKeyword.filter(function(value){
        let qq = value.match(keyword);
        if(qq !== null && (inputRegion === region || inputCategory === category)){
          return fakeArr.push(number)
        }  
        // return value.match(keyword);
      })
    });
    props.funcs(fakeArr);
    setKeyword("");
    return fakeArr;
  }

  return (
        <div className='search'>
          <div className="searchbar container">
            <div className="input">
              <img src={icon_search} width="20px" height="20px"/>
              <input type="text" placeholder="關鍵字(職稱、專業技能)" value={keyword} onChange={(e) => (setKeyword(e.target.value))}/>
            </div>
            <div className="button">
              <select className="select" onChange={(e) => {setRegion(e.target.value)}}>
                <option value="" disabled selected >地區</option>
                <option value="台北市">台北市</option>
                <option value="新北市">新北市</option>
                <option value="桃園市">桃園市</option>
                <option value="台中市">台中市</option>
                <option value="台南市">台南市</option>
                <option value="高雄市">高雄市</option>
                <option value="基隆市">基隆市</option>
                <option value="新竹市">新竹市</option>
                <option value="新竹縣">新竹縣</option>
                <option value="苗栗縣">苗栗縣</option>
                <option value="彰化縣">彰化縣</option>
                <option value="南投縣">南投縣</option>
                <option value="雲林縣">雲林縣</option>
                <option value="嘉義市">嘉義市</option>
                <option value="嘉義縣">嘉義縣</option>
                <option value="屏東縣">屏東縣</option>
                <option value="宜蘭縣">宜蘭縣</option>
                <option value="花蓮縣">花蓮縣</option>
                <option value="台東縣">台東縣</option>
                <option value="澎湖縣">澎湖縣</option>
                <option value="金門縣">金門縣</option>
                <option value="連江縣">連江縣</option>
              </select>
              <select className="select" onChange={(e) => {setCategory(e.target.value)}}>
                <option value="" disabled selected>類別</option>
                <option value="商業">商業</option>
                <option value="工程">工程</option>
                <option value="農牧業">農牧業</option>
                <option value="服務業">服務業</option>
                <option value="其他">其他</option>
              </select>
              <button  onClick={searchMission}>搜尋</button> 
            </div>
          </div>
        </div>
        
  )
}

export default SearchBar;