import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useUserAuth } from '../context/UserAuthContext';
import useRWD from './windowWidth_listener';
import { db } from '../config/firebase';
import { collection, getDocs } from "firebase/firestore";
import icon_clock from "../images/design_after/icon_clock.svg";
import icon_location from "../images/design_after/icon_location.svg";
import icon_lastpage from "../images/design_after/icon_lastpage.svg";
import icon_nextpage from "../images/design_after/icon_nextpage.svg";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./style/emergency.css";

const options = {
  margin: 24,
  responsiveClass: true,
  smartSpeed: 1000,
  loop: true,
  dots: false,
  nav: true,
  responsive: {
      0: {
        items: 1.5,
      },
      767: {
        items: 2.5,
      },
      991: {
        items: 3.5,
      },
      1600: {
        items: 4.5,
      },
      1919: {
        items: 5,
      },
  },
}

const EmergencyComponent = () => {
  let [g, setG] = useState([0]);
  let navigate = useNavigate();
  const { user } = useUserAuth();
  const device = useRWD();

  useEffect(() => {
    FetchEmergenctData()
    .then((res) => {
      setG(res);
    })
    .catch((err) => {
      console.log(err);
    })
  },[])

  const FetchEmergenctData = async () => {
    let arrayF = [];
    try{
      const querySnapshot = await getDocs(collection(db, "mission"));
      querySnapshot.forEach((doc) => {
          const today = new Date();
          const down = new Date(doc.data().take_down.seconds*1000);
          if(doc.data().level === "緊急任務(試用期免費 上架24小時)" && today < down){
            let arrayS = [
              doc.data().category,//[0]
              doc.data().title,//[1] *
              doc.data().hourlyRate,//[2]
              doc.data().location,//[3]
              doc.data().description,//[4] // description * 
              doc.data().duration?.start,//[5]
              doc.data().duration?.end,//[6]
              doc.data().poster_name,//[7]
              doc.data().remark,//[8] *
              doc.data().file,//[9]
              doc.id,//[10]
              doc.data().take_down,//[11]
              doc.data().period,//[12]
              doc.data().week,//[13]
              doc.data().level,//[14]
              doc.data().applicant,//[15]
              doc.data().salaryMethod,//[16]
              doc.data().pieceRate,//[17]
              doc.data().profession,//[18]
            ];
            arrayF.push(arrayS);
          }
      });
    }catch(err){
      console.log("catch", err);   
      console.log(err.message);   
    }
      return arrayF;
    }

  function EmergencyMission(props){

    const numbers = props.numbers;
    const totalMission = numbers.map((number, i) => 
    <div className='item'>
      <div className="card-em" key={i}>
        <div className="hover hover_font" onClick={() => {
          if(user.email){
            navigate(`/callback/${number[10]}`);
          }else{
            navigate('/signin');
          }
        }}>
          <div className="text">
            <h3>{number[1]}</h3>
            <div className="job_catergory"><p>{number[7]} | {number[0]}</p></div>
            {number[16] ?  
              <div className="salary"><span>NT$ {number[17]}/件</span></div>
            :
              <div className="salary"><span>NT$ {number[2]}/時</span></div>
            }
            {device == "pc" && 
            <>
              <div className="berow"><img src={icon_clock} width="20px" height="20px" /><p>{number[5]?.replace(/-/g, "/")} ~ {number[6]?.slice(5).replace(/-/g, "/")}</p></div>
              <div className="berow"><img src={icon_location} width="20px" height="20px"/><p>{number[3]}</p></div>
            </>}
          </div>
        </div>

        <div className="hover hover_behind">
          <div className="text">
            <p className="limittext">{number[4]}</p>
            <button onClick={() => {
            if(user.email){
              navigate(`/callback/${number[10]}`);
            }else{
              navigate('/signin');
            }
            }}>詳細資料
            </button>
          </div>
        </div>
      </div>
    </div>
    )

    return (
      
        <OwlCarousel 
          className='owl-theme cover' 
          navText={[
            `<img src=${icon_lastpage} className="arrow prev"></img>`,
            `<img src=${icon_nextpage} className="arrow next"></img>`,
          ]}
          {...options}
          >
          {/* <a href='#' class='item' onClick={() => {
                    navigate("/testUI");
                    }}>
            <div className="card-em">
              <div className="hover hover_font">
                <div className="text">
                  <h3>範例－緊急</h3>
                  <div className="job_catergory"><p>王大明 | 其他</p></div>
                  <div className="salary"><span>NT$ 250/時</span></div>
                  {device == "pc" && 
                  <>
                    <div className="berow"><img src={icon_clock} width="20px" height="20px" /><p>2023/01/01 10:00 ~ 01/01 21:00</p></div>
                    <div className="berow"><img src={icon_location} width="20px" height="20px"/><p>台北市忠孝東路一段一號</p></div>
                  </>}
                </div>
              </div>
              <div className="hover hover_behind">
                <div className="text">
                  <p className="limittext">幫我打蟑螂</p>
                  <button onClick={() => {
                    navigate("/testUI");
                    }}>詳細資料
                  </button>
                </div>
              </div>
            </div>
          </a> */}
          {totalMission}
        </OwlCarousel>
    )
  }
  
  return <EmergencyMission numbers={g}/>

}

export default EmergencyComponent;