import React, { useState, useRef } from 'react';
import {provider} from "../config/firebase";
import { addMember } from '../databaseSevices/member';
import { auth, db } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, signInWithRedirect} from 'firebase/auth';

import "./style/signbutton.css";

const SignbuttonComponent = () => {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef(null)
  const today = new Date();
    const handleChangeEmail = (e) => {setEmail(e.target.value);};  
    const handleChangePassword = (e) => {setPassword(e.target.value);};
  
    const handleSignin = () => {
      signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        alert("welcome!" );
        window.location.reload();
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        switch(errorCode){
          case "auth/invalid-email":
            setErrorMessage("信箱不存在");
            break;
          case "auth/user-not-found":
            setErrorMessage("信箱不存在");
            break;
          case "auth/wrong-password":
            setErrorMessage("密碼錯誤");
            break;
          default:
          }
      });
    };
    const handleClickEnter = (e) => {
      if(e.key == "Enter"){
        console.log("123");
        handleSignin();
      }else{
        return false;
      }
    };

  const googleSignin = () => {
    auth.languageCode = 'it';
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    signInWithPopup(auth, provider)
    .then(async (result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;
      const GData = result.user.reloadUserInfo;
      //非初次登入
      const docSnap = await getDoc(doc(db, "user",GData.localId));
      if (docSnap.exists()) {

      } else {
        const personalData = {
          Authenticate: false, 
          role: "自然人",
          id: GData.localId,
          phoneNumber: null,
          birth: null,
          email: GData.email,
          username: GData.displayName,
          skill: null,
          signupDate: today,
          avatar: null,
        }
        setDoc(doc(db, "user", GData.localId), personalData);
      }
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
    });
  };
  
   const facebookSignin =  () => {
    const provider = new FacebookAuthProvider();    
    signInWithPopup(auth, provider)
      .then(async (result) => {
        auth.languageCode = 'it';
        const user = result.user;
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const fbData = result.user.reloadUserInfo;
        //初次登入過
        const docSnap = await getDoc(doc(db, "user",fbData.localId));
        if (docSnap.exists()) {

        } else {
          const personalData = {
            Authenticate: false,
            role: "自然人",
            id: fbData.localId,
            phoneNumber: null,
            email: fbData.email,
            birth: null,
            username: fbData.displayName,
            skill: null,
            signupDate: today,
            avatar: null,
          }
          setDoc(doc(db, "user", fbData.localId), personalData);
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        const errorMessage = error.message;
        console.log(errorMessage);
        const credential = FacebookAuthProvider.credentialFromError(error);
        console.log(credential);
      });
   }
  return (
  <div>
    <button type="button" className="btn btn-outline-light" data-bs-toggle="modal" data-bs-target="#exampleModal6" >      
      登入
    </button>
    <div className="modal fade" id="exampleModal6" tabindex="-1" aria-labelledby="exampleModalLabel6" aria-hidden="true" role='dialog'>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel6">登入</h5>
            <button id="close" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={inputRef}></button>
          </div>
          
          <div className="modal-body">
            <div className="mb-2">
              Email
              <input 
                type="email" 
                className="form-control" 
                id="floatingInput" 
                value={email} 
                onChange={handleChangeEmail} 
                />
            </div>
            <div className="">
              Password
              <input 
                type="password"  
                className="form-control" 
                id="floatingPassword" 
                value={password}
                onChange={handleChangePassword}  
                onKeyDown={handleClickEnter}
              />
            </div>

            { errorMessage && <h6 color='red'>{errorMessage}</h6> }
            <button 
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            aria-label="Close" 
            onClick={() => handleSignin()}
            >登入
            </button>
            <a href="/register" aria-disabled="true">註冊</a>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onClick={googleSignin}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-google" viewBox="0 0 20 20">
            <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/></svg>
            使用Google登入</button>

            <button type="button" className="btn fb-loading bouncing-loader" aria-label="Close" onClick={facebookSignin} >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-facebook" viewBox="0 0 20 20">
            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/></svg>
            使用Facebook登入<span calss="load loading"></span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default SignbuttonComponent;