import React, { useState } from 'react'
import {useNavigate} from "react-router-dom";
import { auth, db } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider} from 'firebase/auth';
import "../component/style/signin.css";

const SigninPage = () => {
  let navigate = useNavigate();
  let [erm, setErm] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  const today = new Date();

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleSignin = () => {
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      navigate("/");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      setErm(errorCode);
      navigate("/signin");
    });
  };

  const googleSignin = () => {
    auth.languageCode = 'it';
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    signInWithPopup(auth, provider)
    .then(async (result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;
      const GData = result.user.reloadUserInfo;
      //非初次登入
      const docSnap = await getDoc(doc(db, "user",GData.localId));
      if (docSnap.exists()) {
        navigate("/");
      } else {
        const personalData = {
          Authenticate: false, 
          role: "自然人",
          id: GData.localId,
          phoneNumber: null,
          birth: null,
          email: GData.email,
          username: GData.displayName,
          skill: null,
          signupDate: today,
          avatar: null,
        }
        setDoc(doc(db, "user", GData.localId), personalData);
        navigate("/");
      }
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
      navigate("/signin");
    });
  };
  
   const facebookSignin =  () => {
    const provider = new FacebookAuthProvider();    
    signInWithPopup(auth, provider)
      .then(async (result) => {
        auth.languageCode = 'it';
        const user = result.user;
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const fbData = result.user.reloadUserInfo;
        //初次登入過
        const docSnap = await getDoc(doc(db, "user",fbData.localId));
        if (docSnap.exists()) {
          navigate("/");
        } else {
          const personalData = {
            Authenticate: false,
            role: "自然人",
            id: fbData.localId,
            phoneNumber: null,
            email: fbData.email,
            birth: null,
            username: fbData.displayName,
            skill: null,
            signupDate: today,
            avatar: null,
          }
          setDoc(doc(db, "user", fbData.localId), personalData);
          navigate("/");
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        const errorMessage = error.message;
        console.log(errorMessage);
        const credential = FacebookAuthProvider.credentialFromError(error);
        console.log(credential);
        navigate("/signin");
      });
   }

  return (
    <div className="container">
      <div className="form-signin">
        <div className="signin">
          <h1 class="mb-3">登入/<a href="/register" aria-disabled="true">註冊</a></h1>
          <div class="form-floating mb-3">
            <input 
              type="email" 
              class="form-control" 
              id="floatingInput" 
              value={email} 
              onChange={handleChangeEmail} 
              placeholder="name@example.com"/>
            <label for="floatingInput">請輸入信箱</label>
          </div>
          <div class="form-floating">
            <input 
              type="password"  
              class="form-control" 
              id="floatingPassword" 
              value={password} 
              onChange={handleChangePassword} 
              placeholder="Password"/>
            <label for="floatingPassword">請輸入密碼</label>
          </div>
          {erm === "auth/wrong-password" && <p style={{color:"red"}}>密碼錯誤</p>}
          {erm === "auth/user-not-found" && <p style={{color:"red"}}>此用戶不存在</p>}
          <div className="buttons_signinpage">
            <button 
            className="btn btn-dark"
            onClick={() => handleSignin()}
              >登入
            </button>

          </div>
          <div className="buttons_signinpage social">
            <button type="button" className="btn btn-danger mb-2" data-bs-dismiss="modal" aria-label="Close" onClick={googleSignin}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-google" viewBox="0 0 20 20">
            <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/></svg>
            Google登入</button>
            <button type="button" className="btn fb-loading bouncing-loader" aria-label="Close" onClick={facebookSignin} >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-facebook" viewBox="0 0 20 20">
            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/></svg>
            Facebook登入<span calss="load loading"></span></button>
          </div>
        </div>
      </div>
    </div> 
  ) 
}

export default SigninPage;