import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthContext';
import useRWD from './windowWidth_listener';
import icon_clock from "../images/design_after/icon_clock.svg";
import icon_location from "../images/design_after/icon_location.svg";
import icon_lastpage from "../images/design_after/icon_lastpage.svg";
import icon_nextpage from "../images/design_after/icon_nextpage.svg";
import "./style/normal.css";

const NormalComponent = (props) => {
    let navigate = useNavigate();
    const { user } = useUserAuth();
    const device = useRWD();
    let [pages, setpages] = useState(1);
    
    const judge = () => {
        if(props.searchresult){
            return props.searchresult;
        }else{
            return props.init;
        }
    }
    const a = judge();
    const allpages = (a.length % 12 === 0 ) ? (Math.floor(a.length/12)) : (Math.floor(a.length/12) +1);
    const pagesbtn = [];
    for (let index = 1; index <= allpages; index++) {
        // pagesbtn.push(<button className="pagebtns" onClick={changepages(index)}>{index}</button>)
        pagesbtn.push(index);
    }
    const btns = () => {
        const f = pagesbtn.map((n, i) =>
            <button key={i} onClick={() => setpages(n)} >{n}</button>        
        )
        return f
    }
    const b = a.slice((pages-1)*12, (pages*12));
    const listItems = b.map((number, i) =>
        <div className="col-lg-3 col-sm-6">
            <div className="box" key={i}>
                <div className="title">
                    <h3>{number[1]}</h3>
                    <div className="job_catergory"><p>{number[7]} | {number[0]}</p></div>
                </div>
                {number[16] ?  
                    <div className="salary"><span>NT$ {number[17]}/件</span></div>
                    :
                    <div className="salary"><span>NT$ {number[2]}/時</span></div>
                }
                <hr></hr>
                <p className="limittext">{number[4]}</p>
                <div className="berow"><img src={icon_clock} width="20px" height="20px" /><p>{number[5]?.replace(/-/g, "/")} ~ {number[6]?.slice(5).replace(/-/g, "/")}</p></div>
                <div className="berow"><img src={icon_location} width="20px" height="20px"/><p>{number[3]}</p></div> 
                <button onClick={() =>{
                if(user.email){
                    navigate(`/callback/${number[10]}`);
                }else{
                    navigate('/signin');
                }
                }}>詳細資料
                </button>
            </div>
        </div>
    );
    return(
        <>
            <div className="normal_component">
                <div className='container'>
                    <div className='list'>
                        <div className="row">

                            {/* <div className="col-lg-3 col-sm-6">
                                <div className="box">
                                    <div className="title">
                                        <h3>範例－一般</h3>
                                        <div className="job_catergory">
                                            <p>王大明 | 工程</p>
                                        </div>
                                    </div>
                                    <div className="salary">NT$ 400/時</div>
                                    <hr></hr>
                                    <p className="limittext">修理水電、馬桶、冷氣</p>
                                    <div className="berow"><img src={icon_clock} width="20px" height="20px" /><p>2023/01/01 10:00 ~ 2023/01/01 21:00</p></div>
                                    <div className="berow"><img src={icon_location} width="20px" height="20px"/><p>台北市忠孝東路一段一號</p></div> 
                                    <button disabled>詳細資料</button>
                                </div>
                            </div> */}
                            {a.length > 0 && listItems}
                        </div>
                    </div>

                    <div className="pages">
                        <div className="pagebtns">
                            {pages > 1 && <img src={icon_lastpage} onClick={() => { setpages(pages - 1)}}/>}
                            <span>{pages}</span>
                            {btns}
                            {b.length%12 == 0 &&<img src={icon_nextpage} onClick={() => { setpages(pages + 1)}}/>}
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}
    

export default NormalComponent