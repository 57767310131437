import { auth } from '../config/firebase';

// <<<<<<< Updated upstream
import { signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword, signOut as firebase_signOut, createUserWithEmailAndPassword, sendSignInLinkToEmail } from "firebase/auth";
import { getMember, addMember } from './member';

// =======
// import { signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword } from "firebase/auth";
// import MemberService from './member';

class AuthService {
    signUp_EmailAndPassword(email: string, password: string) {
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                
                // 直接導入資料至firebase/firestore
                // this.addUserPorfile(user.uid, user.email, '自然人')
                alert("succeed!");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(errorMessage);
                // ..
            });
    }}
// >>>>>>> Stashed changes

async function signUp_EmailAndPassword(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;

            // 直接導入資料至firebase/firestore
            addUserPorfile(user.uid, user.email, '自然人')

            return true;
        })
        .catch((error) => {
            return false;
        });
}

async function signOut() {
    return firebase_signOut(auth).then(() => {
        return true
    }).catch(() => {
        return false
    });

}

function signIn(method: string, email?: string, password?: string) {
    switch (method) {
        case 'google':
            return signIn_Google();
        case 'email':
            if ((typeof email === "string") && (typeof password === "string")) {
                return signIn_EmailAndPassword(email, password);
            }
            return new Promise<boolean>((resolve, reject)=>{ resolve(false) });
        default:
            return new Promise<boolean>((resolve, reject)=>{ resolve(false) });
    }
}

async function signIn_Google() {
    const provider = new GoogleAuthProvider();
    /**
     * https://developers.google.com/identity/protocols/oauth2/scopes 查詢
     */
    // provider.addScope('https://www.googleapis.com/auth/userinfo.profile'); //查看您的個人資訊，包括您已公開的任何個人資訊
    // provider.addScope('https://www.googleapis.com/auth/user.phonenumbers.read') //查看及下載您的個人電話號碼
    // provider.addScope('https://www.googleapis.com/auth/userinfo.email') //查看您的主要 Google 帳戶電子郵件地址
    // provider.addScope('https://www.googleapis.com/auth/user.gender.read') //查看您的性別
    // provider.addScope('https://www.googleapis.com/auth/user.birthday.read') //查看及下載您的確切出生日期


    return signInWithPopup(auth, provider)
        .then((result) => {
            // The signed-in user info.
            let user = result.user;

            // 直接導入資料至firebase/firestore
            addUserPorfile(user.uid, user.email, '自然人');

            return true;

        }).catch((error) => {
            return false;
        });
}

async function signIn_EmailAndPassword(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password)
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
}

function addUserPorfile(uid: string, email: string | null, identity: string = '自然人') {
    getMember(uid).then(success => {
        if (success) {

        } else {
            let docData = {
                skill: [],
                license: [],
                expertise: [],
                tag: {
                    skill: []
                },
                profile: {
                    email: email,
                    phone: null,
                    tel: null,
                    identity: identity,
                    birth: null
                },
                role: '',
                timestamp: undefined
            }
            addMember(uid, docData)
        }
    })
}
function AuthenticationEmail(email: string){

  //Email 認證信
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'http://localhost:3000/',
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: 'com.example.ios'
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },
    // dynamicLinkDomain: 'touload.com'
  };
  sendSignInLinkToEmail(auth, email, actionCodeSettings)
  .then(() => {
    // The link was successfully sent. Inform the user.
    // Save the email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
    window.localStorage.setItem('emailForSignIn', email);
    // ...
  })
} 

export {
    signIn,
    signIn_Google,
    signUp_EmailAndPassword,
    signOut,
    AuthenticationEmail,
};