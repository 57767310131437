import React from 'react'
import {useNavigate } from "react-router-dom";
import jobtype1 from "../images/design_after/jobtype1.jpg";
import jobtype2 from "../images/design_after/jobtype2.jpg";
import jobtype3 from "../images/design_after/jobtype3.jpg";
import content1 from "../images/design_after/content1_homepage.jpg";
import content2 from "../images/design_after/content2.png";
import background_homepage_ipad from "../images/design_after/background_homepage_ipad.png";
import FooterComponent from '../component/footer_component';
import EmergencyComponent from '../component/emergency_component.js';
import TOP5Component from '../component/TOP5_component';
import useRWD from '../component/windowWidth_listener';
import background_homepage from "../images/design_after/background_homepage.png";
import title_homepage from "../images/design_after/title_homepage.svg";
import underline from "../images/design_after/underline_homepage.svg";
import "../component/style/homepage.css"

const HomePage = () => {
    let navigate = useNavigate();
    const device = useRWD();
  return (
    <>
        <div className="cover">
            {device === "pc" && <img id="background_homepage" src={background_homepage}/>}
            {device !== "pc" && <img id="background_homepage" src={background_homepage_ipad}/>}
            <div className="emergencyComponent">
                <EmergencyComponent/>
            </div>
        </div>
        {/* 全新的工作型態 */}
        <div className="introduce">
            <div className="container">
                <h2>全新的工作型態</h2>
                <div className="workType">
                    <div className="describe">
                        <img src={jobtype1} alt="pictures"/>
                        <p>去除繁複的面試、履歷，簡單的配對、了解工作內容後即可開始。</p>
                    </div>
                    <div className="describe">
                        <img src={jobtype2}  alt="pictures"/>
                        <p>數週、數日、甚至數小時的工時單位，用最小的時間、人力達到最專業的品質。</p>
                    </div>
                    <div className="describe">
                        <img src={jobtype3} alt="pictures"/>
                        <p>達成任務後獲得名聲，累計名聲越多、階級越高，獲得越多額外酬庸以及曝光率，甚至可以被指定聘請。</p>
                    </div>
                </div>
            </div>
        </div>
        <TOP5Component/>
        {/* 五星級專業傭兵 */}
        <div className="goodAt">
            <div className="fiveStars container">
                <img src={content1} content2="680px" height="480px" alt="pictures"/>
                <div className="descripition">
                    <div className="title">
                        <h1>五星級專業傭兵</h1>
                    </div>
                    <p>不怕神一樣的對手</p>
                    <p>只怕豬一樣的隊友?</p>
                    <p>互評制度讓你更信任你的雇主/傭兵</p>
                    <button className="w-80 btn btn-lg btn-dark"onClick={() => { navigate("/rule"); }} >查看規則</button>
                </div>
            </div>
            <div className="HR">
                <div className="container">
                    <div className="descripition">
                        <div className="title">
                            <h1>找人不再困難</h1>
                        </div>
                        <p>缺工?急徵人?找專業幫手?季節性工時?</p>
                        <p>建立精確的人力資源，讓每一分錢用得更有價值</p>
                    </div>
                    <img src={content2} alt="pictures"/>
                </div>
            </div>
        </div>

    </>

  )
};


export default HomePage;