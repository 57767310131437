import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserAuth } from '../context/UserAuthContext';
import { db } from '../config/firebase';
import { doc, getDoc, updateDoc, setDoc, collection, addDoc } from "firebase/firestore";
import { getOneMission, updateMission } from '../databaseSevices/mission';
import icon_clock from "../images/design_after/icon_clock.svg";
import icon_location from "../images/design_after/icon_location.svg";
import icon_lastpage from "../images/design_after/icon_lastpage.svg";
import icon_nextpage from "../images/design_after/icon_nextpage.svg";
import "../component/style/mymission.css";

const MymissionPage = () => {
  const { user } = useUserAuth();
  let navigate = useNavigate();
  let [ PM, setPM ] = useState([]);  
  let [pages, setpages] = useState(1);

  useEffect(() => {
    GetProvideMission()
    .then((data) => {
      setPM(data);
    })
    .catch((err) => {
      setPM(false);
    })
  },[user.id])

  const GetProvideMission = async () => {
    const docSnap = await getDoc(doc(db, "user", user.id));
    if(docSnap.data().provideMission ){
      return (docSnap.data().provideMission);
    }else {
      return false
    }
  }
  const GetApplicant = async (isMID, MIDname) => {
    const mission = await getOneMission(isMID);
    const applicants = mission.applicant || null;
    window.scrollTo(0,0); 
    navigate({
      pathname:"/member/applicant",
      search: `?mission=${isMID}`},
      {state:{
        applicants: applicants,
        missionName: MIDname,
        missionId: isMID,
    }})
  }

  const allpages = (PM.length % 6 === 0 ) ? (Math.floor(PM.length/6)) : (Math.floor(PM.length/6) +1);
  const pagesbtn = [];
  for (let index = 1; index <= allpages; index++) {
    // pagesbtn.push(<button className="pagebtns" onClick={changepages(index)}>{index}</button>)
    pagesbtn.push(index);
  }
  const eachpage = pagesbtn.map((n, i) => {
    if(pages !== n){
      return <button key={i} onClick={() => setpages(n)} >{n}</button>
    }});
  const renderMission = PM ? PM.slice((pages-1)*6, (pages*6)) : null
  const particalMission = renderMission?.map((element, i) => {
    if(new Date() > new Date(element.take_down.seconds*1000)){
      return(
        <div className="provideMission expired" key={i}>
          <div div className="text">
            <h5>{element.title}</h5><span style={{color: "red"}}>    (已過期)</span>
            <div className="job_catergory"><p>{element.poster_name} | {element.category}</p></div>
            {element.salaryMethod?
              <div className="salary"><span>NT$ {element.pieceRate}/次</span></div>
            :
              <div className="salary"><span>NT$ {element.hourlyRate}/時</span></div>
            }
            <div className="berow"><img src={icon_clock} width="16px" height="16px" /><p>{element.duration.start?.replace(/-/g, "/")} ~ {element.duration.end?.slice(5).replace(/-/g, "/")}</p></div>
            <div className="berow"><img src={icon_location} width="16px" height="16px"/><p>{element.location}</p></div>
          </div>
          <div className="btn-sec">
            <button id="showmission" disabled>查看任務</button>
            <button id="showapplicant" disabled>應徵者</button>
          </div>
        </div>
      )
    }else{
      return(
        <div className="provideMission" key={i}>
          <div div className="text">
            <h5>{element.title}</h5>
            <div className="job_catergory"><p>{user.username} | {element.category}</p></div>
            {element.salaryMethod?
              <div className="salary"><span>NT$ {element.pieceRate}/次</span></div>
            :
              <div className="salary"><span>NT$ {element.hourlyRate}/時</span></div>
            }
            <div className="berow"><img src={icon_clock} width="16px" height="16px" /><p>{element.duration.start?.replace(/-/g, "/")} ~ {element.duration.end?.slice(5).replace(/-/g, "/")}</p></div>
            <div className="berow"><img src={icon_location} width="16px" height="16px"/><p>{element.location}</p></div>
          </div>
          <div className="btn-sec">
            <button id="showmission" onClick={() => {
              navigate(`/callback/${element.missionId}`)
            }}>查看任務
            </button>
            <button id="showapplicant" onClick={() => {
              GetApplicant(element.missionId, element.title);
              }}>應徵者
            </button>
          </div>
        </div>
      )
    }
  }

  );
  return (
  <div className="mymission">
    <h3>現正發行的任務</h3>
    <hr/>
    {PM ?(
      <>
        <div className="grid">
          {particalMission}
        </div>
        <div className="pages">
          <div className="pagebtns" style={{background: "#FFFFFF"}}>

            {pages > 1 && <img src={icon_lastpage} onClick={() => { setpages(pages - 1)}}/>}
            <span>{pages}</span>
            {eachpage}
            {pages < allpages  &&<img src={icon_nextpage} onClick={() => { setpages(pages + 1)}}/>}
          </div>
        </div>
      </>
    ):(
      <h4>您目前尚未建立任何任務</h4>
    )}
  </div>
  )
}

export default MymissionPage