import react from 'react'
import { useNavigate } from "react-router-dom";
import flag_pro from "../images/design_after/flag_pro.svg";
import flag_ontime from "../images/design_after/flag_ontime.svg";
import flag_complete from "../images/design_after/flag_complete.svg";
import icon_button from "../images/design_after/icon_button.svg";
import icon_buttongray from "../images/design_after/icon_buttongray.svg";
import pricetag from "../images/design_after/pricetag.svg";
import emertag from "../images/design_after/emer.svg";
import TOP5Component from '../component/TOP5_component';
import sloganImg_bosspage1 from "../images/design_after/sloganImg_bosspage1.svg";
import sloganImg_bosspage2 from "../images/design_after/sloganImg_bosspage2.svg";
import sloganImg_bosspage3 from "../images/design_after/sloganImg_bosspage3.svg";
import FooterComponent from '../component/footer_component';
import "../component/style/boss.css";

const BossPage = () => {
    let navigate = useNavigate();
  return (
    <div>
        <div className="bosspageCover">
            <div className="bosspageContent">
                <h1>快速尋找專業、短期人才</h1>
                <div className="slogantag">
                    <img src={flag_pro} />
                    <img src={flag_ontime} />
                    <img src={flag_complete} />
                </div>
                <div className="sloganMobile">
                    <span>
                        即時上工
                    </span>
                    <span>
                        解決缺工問題
                    </span>
                </div>
                <p>學生寒暑假打工、餐廳內外場服務生、工廠組裝線工人</p>
                <p>亦是專業技術人員、季節性工時、短期兼職</p>
                <p>隨徵隨有，就到拓路</p>
                <button className="bosspage_button" onClick={() => {navigate("/missionform")}}><img src={icon_button}/>&nbsp;建&nbsp;立&nbsp;任&nbsp;務</button>
            </div>
        </div>
        <div className="price">
            <h1>選擇最適合的方案(試營運期間--免費)</h1>
            <div className="priceList">
                <div className="choice">
                    <h5>3天</h5>
                    <div className="hover_gray">
                        <p>NT$</p>
                        <h1> ̶2̶0̶0̶-</h1>
                        <p>/天</p>
                    </div>
                    <p>總價$600</p>
                    <button className="bosspage_button" onClick={() => {navigate("/missionform")}}>&nbsp;購&nbsp;買&nbsp;方&nbsp;案&nbsp;</button>
                </div>
                <div className="choice">
                    <h5>5天</h5>
                    <div className="hover_gray">
                        <p>NT$</p>
                        <h1> ̶1̶8̶0̶-</h1>
                        <p>/天</p>
                    </div>
                    <p>總價$900</p>
                    <button className="bosspage_button" onClick={() => {navigate("/missionform")}}>&nbsp;購&nbsp;買&nbsp;方&nbsp;案&nbsp;</button>
                </div>
                <div className="choice">
                    <h5>10天</h5>
                    <div className="hover_gray">
                        <p>NT$</p>
                        <h1> ̶1̶5̶0̶-</h1>
                        <p>/天</p>
                    </div>
                    <p>總價$1500</p>
                    <button className="bosspage_button" onClick={() => {navigate("/missionform")}}>&nbsp;購&nbsp;買&nbsp;方&nbsp;案&nbsp;</button>
                </div>
                <div className="choice">
                    <div className="cp"><img src={pricetag}/></div>
                    <h5>20天</h5>
                    <div className="hover_gray">
                        <p>NT$</p>
                        <h1> ̶1̶2̶0̶-</h1>
                        <p>/天</p>
                    </div>
                    <p>總價$2400</p>
                    <button className="bosspage_button" onClick={() => {navigate("/missionform")}}>&nbsp;購&nbsp;買&nbsp;方&nbsp;案&nbsp;</button>
                </div>
                <div className="special">
                    <div className="cp"><img src={emertag}/></div>
                    <h5>24小時</h5>
                    <div className="hover_gray">
                        <p>NT$</p>
                        <h1> ̶2̶2̶0̶-</h1>
                        <p>/天</p>
                    </div>
                    <p>總價$220/天</p>
                    <button onClick={() => {navigate("/missionform")}}>&nbsp;購&nbsp;買&nbsp;方&nbsp;案&nbsp;</button>
                </div>
            </div>
            <a className="more" href='#'>了解更多</a>
        </div>
        <TOP5Component/>

        <div className="description">
            <div className="container">
                <h1>三大優勢<br/>幫您解決缺工難題</h1>

                <div className="bosspage_cube1 cube">
                    <div className='word'>
                        <h2>互<span className="halfbackground">評制度</span></h2>
                        {/* <h2>互評制度</h2> */}
                        <h2>不怕豬隊友</h2>
                        <p>不怕神級對手，互評制度讓您更信任您的雇主/傭兵</p>
                        <button className="description_button" onClick={() => {navigate("/missionform")}}><img src={icon_buttongray}/>&nbsp;建&nbsp;立&nbsp;任&nbsp;務</button>                </div>
                    <img src={sloganImg_bosspage1}></img>

                </div>

                <div className="bosspage_cube2 cube">
                    <img src={sloganImg_bosspage2}></img>
                    <div className="word2">
                        <h2>簡<span className="halfbackground">單對話</span></h2>
                        <h2>工作一拍即合</h2>
                        <p>使用聊天功能，快速理解工作內容</p>
                        <p>遵守規範，勞雇雙方更有保障</p>
                        <button className="description_button" onClick={() => {navigate("/missionform")}}><img src={icon_buttongray}/>&nbsp;建&nbsp;立&nbsp;任&nbsp;務</button>                </div>
                </div>

                <div className="bosspage_cube3 cube">
                    <div className='word'>
                        <h2>本週<span className="halfbackground">TOP5</span></h2>
                        <h2>精準雇用人才</h2>
                        <p>您可以主動詢問各行業的精英--每月TOP5傭兵</p>
                        <p>為您提供最專業的服務</p>
                        <button className="description_button" onClick={() => {navigate("/missionform")}}><img src={icon_buttongray}/>&nbsp;建&nbsp;立&nbsp;任&nbsp;務</button>
                    </div>
                    <img src={sloganImg_bosspage3}></img>
                </div>
            </div>
        </div>

    </div>
  )
}

export default BossPage;
