import React,{ useEffect, useState } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom'
import { useUserAuth } from '../context/UserAuthContext';
import { getAuth } from "firebase/auth";

const PrivateRoutes = () => {
  const { user } = useUserAuth();
  return (
    user ? <Outlet/> : <Navigate to="/signin"/>
  )
  
}

export default PrivateRoutes;