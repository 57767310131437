import { createSlice } from "@reduxjs/toolkit";

export const navSateSlice = createSlice({
    name: "navstate",
    initialState: {
        welNav: 1,
    },
    reducers: {
        increment(state) {
            state.welNav += 1
          },
        decrement(state) {
            state.welNav -= 1
        },
        incrementByAmount(state, action) {
            state.welNav += action.payload
        },
    },
})

export const selectNavState = (state) => state.navstate.welNav;
export const {increment, decrement, incrementByAmount} = navSateSlice.actions;
export default navSateSlice.reducer;