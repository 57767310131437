import React, { useRef } from 'react';
import Logo_row from "../images/design_after/Touload_whiterow.svg";
import Logo_column from "../images/design_after/Logo_column.svg";
import icon_global from "../images/design_after/icon_globe.svg";
import "./style/nav.css";
import useRWD from './windowWidth_listener';
import SignbuttonComponent from './signbutton_component';
import { useUserAuth } from '../context/UserAuthContext';
import { signOut } from '../databaseSevices/authenticate';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { selectNavState } from '../redux/store/slice/navstate';

const NavComponent = () => {
    const { user } = useUserAuth();
    const RWD = useRef(null);
    const device = useRWD();
    const welState = useSelector(selectNavState)
  return (
    <div className="navbar">
        
        {device === "pc" && 
        <>
            <a href="/"><img id="logo_row" src={Logo_row}/></a>
            <div className="link">
                <li>
                    <a className={welState === 6 && "highlight"} href="/rule" >規則</a>
                </li>
                <li>
                    <a href="/boss">成為雇主</a>
                </li>
                <li>
                    <a href="/mercenary">成為傭兵</a>
                </li>
                {/* <li>
                    <a className={welState === 4 && "highlight"} href="/search/keyword">所有任務</a>
                </li> */}
                { user &&
                <>
                    <li>
                        <a href="/welcome">快速上手</a>
                    </li>
                    <li>
                        <a className={welState === 3 && "highlight"} href="/missionform">建立任務</a>
                    </li>

                </>
                }
            </div>
            <div className="setting">
                <div className="signButton">
                    { user ? 
                        <a className={welState === 2 && "highlight"} href="/member/profile" style={{color: "#FFFFFF" , textDecoration: "none"}}>{user.username}</a> :
                        <SignbuttonComponent/>
                    }
                </div>
                <div className="dropdown">
                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={icon_global}/>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
                        <li><a className="dropdown-item" href="#">中文</a></li>
                        <li><a className="dropdown-item" href="#">English</a></li>
                    </ul>
                </div>
            </div>
        </>

        }
        {device !== "pc" && 
            <>
                <a href="/"><img id="logo_row" src={Logo_column}/></a>
                {/* <img id="logo_row" src={icon_navButton} onClick={() => {RWD.current.click();}}/> */}
                <button className="menu-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"ref={RWD}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div className="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                    <div claclassNamess="offcanvas-header">
                        <div className="signButton">
                        { user ? 
                            <a href="/member/profile"><h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel">{user.username}</h5></a> :
                            <SignbuttonComponent/>
                        }
                        </div>
                        
                        <button type="button" class="close" data-bs-dismiss="offcanvas" aria-label="Close">
                            <span className="material-symbols-outlined">
                                close
                            </span>
                        </button>
                    </div>
                    <div className="offcanvas-body">
                        <div className="link_RWD">
                            <li>
                                <a className={welState === 6 && "highlight"} href="/rule" >規則</a>
                            </li>
                            <li>
                                <a href="/boss">成為雇主</a>
                            </li>
                            <li>
                                <a href="/mercenary">成為傭兵</a>
                            </li>
                            {/* <li>
                                <a className={welState === 4 && "highlight"} href="/search/keyword">所有任務</a>
                            </li> */}
                            { user &&
                            <>
                                <li>
                                    <a href="/welcome">快速上手</a>
                                </li>
                                <li>
                                    <a className={welState === 3 && "highlight"} href="/missionform">建立任務</a>
                                </li>

                                <hr style={{width:'50%', color: "green"}}/>
                                <li className="border-top my-3"/>
                                <li>
                                    <a href="/member/profile">個人資料</a>
                                </li>
                                <li>
                                    <a href="/member/myjob">工作歷程</a>
                                </li>
                                <li>
                                    <a href="/member/mymission">提供任務</a>
                                </li>
                                <li>
                                    <a href="#">設定</a>
                                </li>
                                <li>
                                    <button onClick={() => {
                                        signOut();
                                        window.location.reload();
                                    }}>登出</button>
                                </li>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </>

        }

    </div>
  )
}

export default NavComponent;