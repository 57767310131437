import { createContext, useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import {onAuthStateChanged} from "firebase/auth";
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db, storage } from "../config/firebase";
const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [ user, setUser ] = useState({});

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const docSnap = await getDoc(doc(db, "user", user.uid));
        if(user.providerData[0].providerId === "password"){
          if(user.emailVerified){
            const Data = Object.assign(docSnap.data(), {Authenticate: true })
            await updateDoc(doc(db, "user", user.uid), Data);
            setUser(Data);
          }else{
            setUser(docSnap.data()); 
          }
        }else{
          if(user.emailVerified){
            const Data = Object.assign(docSnap.data(), {id: user.uid, avatarURL: user.providerData[0].photoURL ? user.providerData[0].photoURL : null, Authenticate: true })
            await updateDoc(doc(db, "user", user.uid), Data);
            setUser(Data);
          }else{
            const Data1 = Object.assign(docSnap.data(), {id: user.uid, avatarURL: user.providerData[0].photoURL ? user.providerData[0].photoURL : null })
            await updateDoc(doc(db, "user", user.uid), Data1);
            setUser(Data1); 
          }
        }
      }else{
        setUser(false)
      } 
    });

  }, []);

  return (
    <userAuthContext.Provider
      value={{ user }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}