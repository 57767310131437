import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthContext';
import { db, storage } from '../config/firebase';
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { doc, getDoc, updateDoc, setDoc, collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { getOneMission, updateMission } from '../databaseSevices/mission';
import icon_file from "../images/design_after/icon_file.svg";
import "../component/style/applicant.css";

const ApplicantPage = () => {
  const location = useLocation();
  const { user } = useUserAuth();
  const [imgURL, setImgURL] = useState([]);
  const inputAvatar = useRef(null);

  const handleHire = async (applicantID, email) => {
    const docSnap = await getDoc(doc(db, "mission", location.state.missionId));
    const oldMission = docSnap.data();
    const allapplicants = oldMission.applicant;
    const whoHired = allapplicants.filter((item, index, array) => {
      return item.userID == applicantID
    });
    const others = allapplicants.filter((item, index, array) => {
      return item.userID !== applicantID;
    });
    const a = Object.assign( whoHired[0] ,{ 
      offer: true,  
      invitation: "no-reply",
    });
    const b = others.push(a);
    // updateMission(MID, oldMission, others);
    updateDoc(doc(db, "mission", location.state.missionId), {
      applicant: others    
    })
    addDoc(collection(db, "mail"),{
      to: [email],
      message: {
        subject: '工作錄取通知!',
        text: '恭喜您日前應徵的雇主已向您送出工作邀請',
        html: 
        `<p>日前您應徵的任務「${location.state.missionName}」 雇主已向您送出工作邀請</p>
          <p>您可以回到Touload中, 「接受/拒絕」此任務</p>
          <a href="${window.location.origin}/member/myjob">前往工作歷程</a>`,
      },
    })
  };

  const getUserLicence = (id) => {
    const arr = [];
    const listRef = ref(storage, `LicenceImgs/${id}`)
    // Find all the prefixes and items.
    listAll(listRef)
    .then((res) => {
      res.items.forEach((itemRef) => {
        getDownloadURL(itemRef)
        .then((url) => {
          // console.log(url);
          setImgURL(arr => [ ...arr, url]);
        })
      });
    }).catch((error) => {
      console.log(error);
    });
    // const f = arr.map((item, i) =>
    //   <li key={i}><a href={item}>Link</a></li>
    // )
    // return <>{f}</>
  }

  const creatChatRoom = async (applicant) => {
    let arr = [];
    // Always Boss first [BossID, ApplicantID]
    const q = query(collection(db, "chat"), where('memberID', 'in', [[user.id, applicant.userID]]));
    const querySnapshot1 = await getDocs(q);
    querySnapshot1.forEach((doc) => {
      arr.push(doc.id);
    });
    if(arr.length > 0){
      // open the chat
      alert(`${applicant.username}已加入`)
    }else{
      await addDoc(collection(db, "chat"), {
        memberName:[user.username, applicant.username],
        memberID: [user.id, applicant.userID],
        conversation: [],
      });
      alert(`已將${applicant.username}加入您的聊天室`)
    }
  }

  let a;
  if(location.state.applicants !== null){
    a = location.state.applicants.map((applicant, i) => 
      <>
        <tr key={i}>
          <td>{applicant?.username}</td>
          <td>{applicant?.userphone}</td>
          <td>{applicant?.useremail}</td>
          <td>{applicant?.userskill?.length > 0 && applicant?.userskill.map((ele) => 
            <p>{ele === null ? "無" : ele}</p>)}
          </td>
          <td>{applicant?.userskillLevel?.length > 0 && applicant?.userskillLevel.map((ele) => 
            <p>{ele === null ? "無" : ele}</p>)}
          </td>
          {/* <td>
            <p>{applicant?.userskill1}</p>
            <p>{applicant?.userskill2}</p>
            <p>{applicant?.userskill3}</p>
          </td> */}

          <td><img src={icon_file} width="20px" height="20px" onClick={() => {
              inputAvatar.current.click();
              getUserLicence(applicant.userID)
            }}/>
          </td>
          <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop"  ref={inputAvatar} hidden/>
          <td><button type="button" className="btn btn-outline-dark"  onClick={() => {creatChatRoom(applicant)}}>聯繫他</button></td>
          <td>
            {applicant?.offer?
            // <button type="button" className="btn btn-dark" disabled>{applicant.invitation === "no-reply" && "尚未回覆"}</button>:
            <button type="button" className="btn btn-dark" disabled>
              {
                {
                'no-reply': '尚未回覆',
                'yes': '接受',
                'no': '拒絕',
                }[applicant.invitation]
              }   
            </button>:
            <button 
              type="button" className="btn btn-outline-dark"
              onClick={(e) => {
                handleHire(applicant.userID, applicant.useremail);
                e.currentTarget.disabled= true;
            }}>送出邀請
          </button> 
          }</td>
        </tr>
        <tr>
          <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">證照－{applicant?.username}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {setImgURL([])}}></button>
                </div>
                <div class="modal-body">
                  {imgURL.length > 0 && imgURL.map((item, i) => {
                  return <a href={item} key={i}>{`檔案${i+1}`}</a>
                  })}
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {setImgURL([])}}>Close</button>
                </div>
              </div>
            </div>
          </div>
        </tr>
      </>
    );
  }

  return (
    <div className="applicant">
        <h4>應徵者</h4>
        <hr/>
        {location.state.applicants !== null ?
          <table>
            <tr className="tabletitle">
              <th>姓名</th>
              <th>電話</th>
              <th>Email</th>
              <th>專長</th>
              <th>專業等級</th>
              <th>證照</th>
              <th>聯絡</th>
              <th>邀請狀態</th>
            </tr>
            <>{a}</>
            
          </table>
          
        : <p>此任務尚無應徵者</p>
        }

    </div>
  )
}

export default ApplicantPage