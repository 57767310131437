import React, { useState } from 'react'
import { useUserAuth } from '../context/UserAuthContext';
import { getAuth, isSignInWithEmailLink } from "firebase/auth";
import { db } from '../config/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import cube1 from "../images/design_after/welcome_cube1.svg";
import cube2 from "../images/design_after/welcome_cube2.svg";
import cube3 from "../images/design_after/welcome_cube3.svg";
import cube4 from "../images/design_after/welcome_cube4.svg";
import cube5 from "../images/design_after/welcome_cube5.svg";
import cube6 from "../images/design_after/welcome_cube6.svg";
import lastbutton from "../images/design_after/lastbutton_welcome.svg";
import nextbutton from "../images/design_after/nextbutton_welcome.svg";
import highlight from "../images/design_after/highlight_welcome.svg";
import arrow2 from "../images/design_after/arrow2_welcome.svg";
import arrow3 from "../images/design_after/arrow3_welcome.svg";
import arrow4 from "../images/design_after/arrow4_welcome.svg";
import arrow5 from "../images/design_after/arrow5_welcome.svg";
import arrow6 from "../images/design_after/arrow6_welcome.svg";
import Logo from "../images/design_after/Logo_welcome.svg";
import "../component/style/welcome.css";
import { useDispatch } from 'react-redux';
import { increment, decrement, incrementByAmount } from '../redux/store/slice/navstate';

const WelcomePage = () => {
  const { user } = useUserAuth();
  const auth = getAuth();
  const dispatch = useDispatch()

  if (isSignInWithEmailLink(auth, window.location.href)) {
    try {
      updateDoc(doc(db, "user", user.id), {
        Authenticate: true, 
    });
    } catch (err) {
      console.error("Error: ", err);
    }
  }
  const [cubestyle, setCubestyle] = useState(0);
  const lastcube = () => {
    if(cubestyle < 0){
      setCubestyle( cubestyle + 450 )
      dispatch(decrement())
    }
  }
  const nextcube = () => {
    if(cubestyle > -2500){
      setCubestyle(cubestyle - 450)
      dispatch(increment())
    }
  }

  return (
    <div className="welcome">
      <div className='highlight d-none'>
        {cubestyle === -450 && <img id="highlight2" src={highlight}/>}
        {cubestyle === -900 && <img id="highlight3" src={highlight}/>}
        {cubestyle === -1350 && <img id="highlight4" src={highlight}/>}
        {cubestyle === -2250 && <img id="highlight6" src={highlight}/>}
      </div>
      <div className="welcome_buttons" >
        <img id="button" src={lastbutton} width="85px" height="85px" onClick={() => {lastcube()}}/>
        {cubestyle === 0 && <h2 id="fade">歡迎您加入Touload的一員</h2>}
        <img id="button" src={nextbutton} width="85px" height="85px" onClick={() => {nextcube() }}/>
      </div>
      <div className='arrow'>
        {cubestyle === -450 && <img id="arrow2" src={arrow2}/> }
        {cubestyle === -900 && <img id="arrow3" src={arrow3}/> }
        {cubestyle === -1350 && <img id="arrow4" src={arrow4}/> }
        {cubestyle === -2250 && <img id="arrow6" src={arrow4}/> }
      </div>

      <div className="welcome_carousel" style={{ 
        transform: `translateX(${cubestyle}px)` 
      }}>
        <div className="welcome_cube header" >
          <h2>簡單六步驟</h2>
          <h2>快速上手！</h2>
        </div>
        
        <div className={cubestyle === 0 ? "highLight" : "welcome_cube"}>
          <div className="title_cube">
            <h1>01</h1>
            <h2>歡迎</h2>
          </div>
          <div className="content_cube">
            <p>加入Touload的一員</p>
          </div>
          <div className="img_cube">
            <img src={cube1} />
          </div>
        </div>
        <div className={cubestyle === -450 ? "highLight" : "welcome_cube"}>
          <div className="title_cube">
            <h1>02</h1>
            <h2>開始</h2>
          </div>
          <div className="content_cube">
            <p>現在您只要到<span>註冊</span>中，完整填寫完<span>個人資料</span>，即可開始使用</p>
          </div>
          <div className="img_cube">
            <img src={cube2}/>
          </div>
        </div>
        <div className={cubestyle === -900 ? "highLight" : "welcome_cube"}>
          <div className="title_cube">
            <h1>03</h1>
            <h2>建立任務</h2>
          </div>
          <div className="content_cube">
            <p>不論個人/法人，都可以到<span>建立任務</span>中，建立您的委託</p>
          </div>
          <div className="img_cube">
            <img src={cube3}/>
          </div>
        </div>
        <div className={cubestyle === -1350 ? "highLight" : "welcome_cube"}>
          <div className="title_cube">
            <h1>04</h1>
            <h2>執行任務</h2>
          </div>
          <div className="content_cube">
            <p>亦是到<span>首頁</span>尋找任務，累積越多五星評價，就有可能登上每週的TOP5傭兵，受其他僱主指定任務</p>
          </div>
          <div className="img_cube">
            <img src={cube4}/>
          </div>
        </div>
        <div className={cubestyle === -1800 ? "highLight" : "welcome_cube"}>
          <div className="title_cube">
            <h1>05</h1>
            <h2>聊天</h2>
          </div>
          <div className="content_cube">
            <p>可以透過<span>聊天功能</span>與雇主直接對話，溝通夠細節的工作內容</p>
          </div>
          <div className="img_cube">
            <img src={cube5}/>
          </div>
        </div>
        <div className={cubestyle === -2250 ? "highLight" : "welcome_cube"}>
          <div className="title_cube">
            <h1>06</h1>
            <h2>規則</h2>
          </div>
          <div className="content_cube">
            <p>雇主/傭兵的詳細規範都在<span>規則</span>中</p>
          </div>
          <div className="img_cube">
            <img src={cube6}/>
          </div>
        </div>
        <div className="welcome_cube header">
          <h2>開始尋找</h2>
          <h2>適合的工作吧！</h2>
        </div>
      </div>
      <img className='logo' src={Logo} style={{padding: "20px"}}/>
    </div>
  )
}

export default WelcomePage