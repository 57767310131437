import React,{ useState, useEffect } from 'react';
import "./style/profile.css";
import { db, auth, storage } from '../config/firebase';
import { sendEmailVerification } from 'firebase/auth'; 
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytesResumable, getDownloadURL, uploadString, listAll , deleteObject} from "firebase/storage";
import { useUserAuth } from '../context/UserAuthContext';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import icon_trash from "../images/design_after/icon_trash.svg";
const ProfileComponent = () => {
  const { user } = useUserAuth();
  let navigate = useNavigate();
  let [lock, setLock] = useState(true);
  let [prelock, setPrelock] = useState(false);
  let [newData, setNewData] = useState("");
  let [newfile, setNewfile] = useState({});

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    setNewData({...newData, [name]: value})
  }
  const handleModify = () =>{
    setLock(false);
    setPrelock(true);
  };
  const handleFile = (inputfile) => {
    const fileNameList = [];
    var file;
    if(inputfile){
      for (var i = 0; i < inputfile.length; i++) {
        // file = inputfile.item(i);
        file = inputfile[i];
        fileNameList.push(file.name);
      }
      return fileNameList
    }else{
      return null
    }
  }

  const compressImg = (file, uid) => {
    if(newfile.length !== 0){
      for (let i = 0; i < file.length; i++) {
        let imageType = file[i].type;
        let reader = new FileReader();
        reader.readAsArrayBuffer(file[i]);
        reader.onload = function (event) {
          let blob = new Blob([event.target.result]);  
          window.URL = window.URL || window.webkitURL;
          let blobURL = window.URL.createObjectURL(blob);  
          let image = new Image();
          image.src = blobURL;
          image.onload = function () {
              let newImage = resize(image, imageType);
              uploadToFirestorage(newImage, uid, file[i].name);
          }
        }
      }
      return true
    }else{
      return false
    }
  }
  
  const resize = (img, type) => {
    let canvas = document.createElement("canvas");
    canvas.width = img.width * 0.7;
    canvas.height = img.height * 0.7;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width * 0.7, img.height * 0.7); // 把圖片畫在畫布上(0,0)作標到(canvas.width,canvas.height)
    let newImg = canvas.toDataURL(type, 0.8); // 0.8是圖片壓縮比
    return newImg;
  }
  
  const uploadToFirestorage = (imgURL, uid, name) => {
    const storageRef = ref(storage, `LicenceImgs/${uid}/${name}`);
    // Data URL string
    uploadString(storageRef, imgURL, 'data_url');
  }

  const deleteStorageFile = () => {
    if(newfile.length !== 0){
      user.licence.map((item, i) => {
        deleteObject(ref(storage, `LicenceImgs/${user.id}/${item}`))
      })
    }else{
      return false
    }
  }

  const handleComfilm = async () => {
    const newProfile = {
      username : newData.name || user.username,
      email : newData.email || user.email,
      birth : newData.birth || user.birth,
      phoneNumber : newData.phoneNumber || user.phoneNumber,
      jobKind : newData.category || "其他",
      // skill : [newData.skill1 || null, newData.skill2 || null, newData.skill3 || null],
      // skillLevel : [newData.skillLevel1 || null, newData.skillLevel2 || null, newData.skillLevel3 || null],=
      skill1: newData.skill1 || null,
      skill2: newData.skill2 || null,
      skill3: newData.skill3 || null,
      skillLevel1: newData.skillLevel1 || null,
      skillLevel2: newData.skillLevel2 || null,
      skillLevel3: newData.skillLevel3 || null,
      licence: handleFile(newfile) || null,
    }
    if (newProfile.username && newProfile.email && newProfile.birth && newProfile.phoneNumber){
      setLock(true);
      setPrelock(false);
      await updateDoc(doc(db, "user", user.id), newProfile)
      .then(() => {
        return deleteStorageFile();
      })
      .then(() => {
        return compressImg(newfile, user.id);
      })
      .then(() => {
        alert("資料更新成功!")
        // navigate(0)
      })
      // .then(() => {navigate('/member/profile')})
      .catch((err) => {
        console.log(err);
        console.log(err.Message);
        alert("資料更新失敗! 請稍後重試")
      })
    }else{
      alert("姓名、email、生日、電話 不可為空")
    }
  };
  // let sk;
  // if(user.skill === undefined || user.skill === null){
  //   sk = null
  // }else{
  //   sk = user.skill
  // }
  // let skLV;
  // if(user.skillLevel === undefined || user.skillLevel === null){
  //   skLV = null
  // }else{
  //   skLV = user.skillLevel
  // }

  return (
    <div className="profile">
      <h3>會員資料</h3>
      <hr/>
      <form>
        <table className="fixform">
          <tr>
            <td width="20%">姓名</td>
            {lock && <td width="100%"><input className="form-control" type="text" defaultValue={user.username} disabled></input></td>}
            {prelock && <td width="100%"><input className="form-control" type="text" name="name" onChange={(e) => handleInputChange(e)}></input></td>}
          </tr>
          <br />
          <tr>
            <td>Email</td>
            <td><input className="form-control" type="text" name="email" defaultValue={user.email} disabled onChange={(e) => handleInputChange(e)}></input></td>
          </tr>
          <br />
          <tr>
            <td>Email認證<br/>{user.Authenticate ==false && <button onClick={() => {
              sendEmailVerification(auth.currentUser);
              alert(`驗證信已發送至${user.email}`)
            }}>發送驗證信</button>}</td>
            <td><input className="form-control" type="text" defaultValue={user.Authenticate} disabled></input></td>
          </tr>
          <br />
          <tr>
            <td>電話</td>
            {lock && <td><input className="form-control" type="text" defaultValue={user.phoneNumber} disabled ></input></td>}
            {prelock && <td><input className="form-control" type="text" name="phoneNumber" onChange={(e) => handleInputChange(e)}></input></td>}

          </tr>
          <br />
          <tr>
            <td>ID</td>
            <td><input className="form-control" type="text" defaultValue={user.id} disabled></input></td>
          </tr>
          <br />
          <tr>
            <td>生日</td>
            {lock && <td><input className="form-control" type="text" defaultValue={user.birth} disabled></input></td>}
            {prelock && <td><input className="form-control" type="text" name="birth" placeholder="yyyy-mm-dd" onChange={(e) => handleInputChange(e)}></input></td>}
          </tr>
          <br />
          <tr>
            <td>技能類別</td>
            {lock && <td><input className="form-control" type="text" defaultValue={user.jobKind} disabled></input></td>}
            {prelock && <td>        
              <select className="form-select" disabled={lock} name="category" onChange={(e) => handleInputChange(e)}>
                <option value="商業">商業</option>
                <option value="工程">工程</option>
                <option value="服務業">服務業</option>
                <option value="其他">其他</option>
              </select>
            </td>}
          </tr>
          <br/>
          <tr>
            <td>專長(至多三項)
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                </svg> */}
                <p>能力:1(普通)~5(專業)</p>
            </td>
            
            <td>
              {lock && 
              <div className='prpfessionTD'>
                <div className='prpfessionText'>
                  <input className="form-control" type="text" defaultValue={user.skill1 || null} disabled ></input>
                  <input className="form-control" type="text" defaultValue={user.skill2 || null} disabled ></input>
                  <input className="form-control" type="text" defaultValue={user.skill3 || null} disabled ></input>
                </div>
                <div className='prpfessionLevel'>
                  <input className="form-control" type="number" defaultValue={user.skillLevel1 || null} min="1" max="5" disabled></input>
                  <input className="form-control" type="number" defaultValue={user.skillLevel2 || null} min="1" max="5" disabled></input>
                  <input className="form-control" type="number" defaultValue={user.skillLevel3 || null} min="1" max="5" disabled></input>
                </div>
              </div>
              }
              {prelock && 
                <div className='prpfessionTD'>
                  <div className='prpfessionText'>
                    <input className="form-control" type="text" name="skill1" onChange={(e) => handleInputChange(e)}></input>
                    <input className="form-control" type="text" name="skill2" onChange={(e) => handleInputChange(e)}></input>
                    <input className="form-control" type="text" name="skill3" onChange={(e) => handleInputChange(e)}></input>
                  </div>
                  <div className='prpfessionLevel'>
                    <input className="form-control" type="number" name="skillLevel1" min="1" max="5" onChange={(e) => handleInputChange(e)}></input>
                    <input className="form-control" type="number" name="skillLevel2" min="1" max="5" onChange={(e) => handleInputChange(e)}></input>
                    <input className="form-control" type="number" name="skillLevel3" min="1" max="5" onChange={(e) => handleInputChange(e)}></input>
                  </div>
                </div>
              }
            </td>
            <td></td>
          </tr>
          <br />
          <tr>
            <td>證照(可複選)</td>
            <td>
              {lock && user.licence?.map((item, i) => {
                return <input className="form-control" type="text" defaultValue={item} key={i} disabled></input>
              })}
              {prelock && <input class="form-control" type="file" accept=".jpeg,.pdf,.png" disabled={lock} multiple onChange={(e) => {
                setNewfile(e.currentTarget.files);
              }} />}
            </td>
          </tr>
        </table>
        <div className="profile-btn">
            {lock && <button className="" type='submit' onClick={() => handleModify()} disabled={prelock}>修改</button> }
            {prelock && <button className="cancel" type='submit' onClick={() => {
              setLock(true);
              setPrelock(false)}}
              disabled={lock}>取消</button> }

            {prelock && <button className=" enter"   type='submit' onClick={() => handleComfilm()}  disabled={lock}>確定</button> }
          </div>
      </form>
    </div>

  )
}

export default ProfileComponent;