import React from 'react';
import {BrowserRouter, Routes , Route} from "react-router-dom";
import { UserAuthContextProvider } from './context/UserAuthContext';
import PrivateRoutes from './config/PrivateRoutes';
import NavComponent from './component/nav-component';
import HomePage from './pages/home_page';
import BossPage from "./pages/boss_page";
import RegisterPage from "./pages/register_page"
import MissionFormPage from './pages/missionForm_page';
import SigninPage from './pages/signin_page';
import RulePage from './pages/rule_page';
import MatchingPage from './pages/matching_page';
import PaymentPage from './pages/payment_page';
import ProfileComponent from './component/profile_component';
import MyjobPage from './pages/myjob_page';
import MymissionPage from './pages/mymission_page';
import Sidebar from './component/sidebar';
import CheckEmailPage from './pages/checkEmail_page';
import ApplicantPage from './pages/applicant_page';
import Chatbox from './component/chatbox';
import WelcomePage from './pages/welcome_page';
import PrivacyPolicyPage from './pages/privacy_policy_page';
import TestUIPage from './pages/testUI_page';
import FooterComponent from './component/footer_component';
import { Provider, useSelector } from 'react-redux';
import { store } from './redux/store';

function App(){
  return (
    <Provider store={store}>
      <UserAuthContextProvider >
        <NavComponent />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MatchingPage/>}/>
              <Route path="/boss" element={<BossPage/>}/>
              <Route path="/register" element={<RegisterPage/>}/>
              <Route path="/signin" element={<SigninPage/>}/>
              <Route path="/rule" element={<RulePage/>}/>
              <Route path="/mercenary" element={<HomePage/>}/>
              <Route path="/privacy_policy" element={<PrivacyPolicyPage/>}/>
              {/* <Route path="/search">
                  <Route path="keyword" element={<MatchingPage/>}/>
                </Route> */}
              <Route element={<PrivateRoutes/>}>
                <Route path="/missionform" element={<MissionFormPage/>}/>
                <Route path="/member" element={<Sidebar/>}>
                  <Route path="profile" element={<ProfileComponent/>}/>
                  <Route path="myjob" element={<MyjobPage/>}/>
                  <Route path="applicant" element={<ApplicantPage/>}/>
                  <Route path="mymission" element={<MymissionPage/>}/>
                </Route>

                <Route path="/testUI" element={<TestUIPage/>}/>
                <Route path="/callback/:missionID" element={<PaymentPage/>}/>
              </Route>
              <Route path="auth"  element={<CheckEmailPage/>}/>
              <Route path="/welcome"  element={<WelcomePage/>}/>
              <Route path="*" element={<h1>Error: 404!</h1>}/> 
            </Routes>
          </BrowserRouter>
        <Chatbox/>
        <FooterComponent/>
      </UserAuthContextProvider>
    </Provider>
  );
}

export default App;
