import React from 'react'
import "./style/TOP5.css";
import head from "../images/design_after/icon_head.svg";
import icon_crown from "../images/design_after/icon_crown.svg";
import icon_laurels from "../images/design_after/icon_laurels.svg";

export const TOP5Component = () => {

  return (

    <div className="top5">
        <h2 style={{color: "white"}}>本月TOP5傭兵(試用期尚未開放)</h2>
        {/* <div className="no">
          <div className="no_circle"><p style={{color: "white"}}>4</p></div>
          <div className="no_circle"><p style={{color: "white"}}>2</p></div>
          <div className="no_circle"><p style={{color: "white"}}>1</p></div>
          <div className="no_circle"><p style={{color: "white"}}>3</p></div>
          <div className="no_circle"><p style={{color: "white"}}>5</p></div>
        </div> */}
        <div className="top5_avatar container">
          <div className="no4 avatar">
            <div className='user_img'>
              <img src={head} />
            </div>
            <div className='detail d-flex'>
              <h3>王大明</h3>
              <p>專業: 網頁設計</p>
            </div>
            <button className="top5_button">聯絡</button>
          </div>
          <div className="no2 avatar">
            <div className='user_img'>
              <img src={head} />
            </div>
            <div className='detail d-flex'>
              <h3>王大明</h3>
              <p>專業: 修理水電</p>
            </div>
            <button className="top5_button">聯絡</button>
          </div>
          <div className="no1 avatar">
            <div className="crown">
              <img src={icon_crown} />
            </div>
            <div className='user_img'>
              <img src={head} />
            </div>
            <div className='detail d-flex'>
              <h3>王大明</h3>
              <p>專業: 木工、室內裝潢</p>
            </div>
            <button className="top5_button">聯絡</button>
            <div className="laurels"><img src={icon_laurels}/></div>
          </div>
          <div className="no3 avatar">
            <div className='user_img'>
              <img src={head} />
            </div>
            <div className='detail d-flex'>
              <h3>王大明</h3>
              <p>專業: 美式餐廳廚師</p>
            </div>
            <button className="top5_button">聯絡</button>
          </div>
          <div className="no5 avatar">
             <div className='user_img'>
              <img src={head} />
            </div>
            <div className='detail d-flex'>
              <h3>王大明</h3>
              <p>專業: 機械製圖</p>
            </div>
            <button className="top5_button">聯絡</button>
          </div>
        </div>
    </div>
  )
}


export default TOP5Component;