import React, { useEffect } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import "./style/footer.css"
import Logo_row from "../images/design_after/Touload_whiterow.svg";
import footer_logo from "../images/design_after/footer-logo.png";
import icon_index from "../images/design_after/icon_index.svg";

export const FooterComponent = () => {
  const currentlocation = window.location.pathname;

  return (
    <>
    { currentlocation !== "/welcome" &&
    <div className="footer">
      <div className="container">
        <div className="black_part">
          <div className="connection">
            <div className="text">
              <ul>
                <h4>傭兵</h4>
                <li><img src={icon_index}/><a href="/rule">規則</a></li>
                <li><img src={icon_index}/><a href="/">探索任務</a></li>
              </ul>
            </div>
            <div className="text">
              <ul>
                <h4>雇主</h4>
                <li><img src={icon_index}/><a href="rule">規則</a></li>
                <li><img src={icon_index}/><a href="/mercenary">本週TOP5傭兵</a></li>
                <li><img src={icon_index}/><a href="/boss">成為雇主</a></li>
              </ul>
            </div>
          </div>
          <div className="imformation">
            <div className='terms'>
              <div>
                <a href='https://docs.google.com/forms/d/e/1FAIpQLSfoKM4VzkDzq2JrzAV-E19Cn4Z-D6WRuvD90LfcgZ4dfeoACQ/viewform?usp=sf_link'>意見箱</a>
              </div>
              <a href="/privacy_policy">
                隱私權
              </a>
              <a href="#">
                服務條款
              </a>
            </div>

            <div>
              <a href='mailto:touload@gmail.com'>touload@gmail.com</a>
            </div>
          </div>
          <div className='footer-logo'>
            <img src={footer_logo}/>
          </div>
        </div>
        <div className="company">
          copyright © 拓路科技 &nbsp; 統一編號 : 93379627
        </div>
      </div>
    </div>
  }
  </>
  )
}

export default FooterComponent;