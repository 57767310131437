import React from 'react'

import "../component/style/rule.css";
import FooterComponent from '../component/footer_component';
export const RulePage = () => {
  return (
    <div>
      <div className='rule'>
        {/* <div className='empolyee'>
              <h1>傭兵規則</h1>
              <p>傭兵負擔手續費案薪資級距收費，完成任務後收款</p>
              <p>線上面試、工作內容洽談，勞資雙方可依定型化契約規定詳盡之內容</p>
              <h2>*評分制度(待開放)</h2>
          </div>
          <div className='empolyer'>
              <h1>雇主規則</h1>
              <p>線上面試、工作內容洽談，勞資雙方可依定型化契約規定詳盡之內容</p>
              <p>若任務完成度不滿意，資方不得任意違約;但雙方同意下，可終止合約，但須負擔手續費</p>
              <p>工時前3小時為試用期，試用期內雙方工作內容談不攏可免費取消此合約(需收手續費)，若工時低於3小時則無適用期。</p>
          </div> */}
          <div className='container'>
            <p>1.線上面試、工作內容洽談，勞資雙方可依定型化契約規定詳盡之內容</p>
            <p>2.勞資雙方須遵守秘密原則，不得提及過往之工作內容、傭兵個資</p>
            {/* <p>3.若任務完成度不滿意，資方不得任意違約;但雙方同意下，可終止合約，但仍須負擔手續費 </p> */}
            <p>3.除了在本網站討論的工作內容，在其餘社交軟體討論之工作內容本公司一蓋不負責 </p>
            <p>4.Email認證完成後才可使用「建立任務」以及「應徵」功能</p>
            <p>5.請妥善利用「聊天室」以及「備註功能」，了解 薪資總額、支付方式以及實際工作內容，以免爭議</p>
            <p>6.一旦配對完成，雙方即為簡易「派遣關係」</p>
            <p>7.試營運期間，暫時不收費</p>
            <p>8.薪資給付方式由僱主及傭兵自行討論和給付，Touload無干涉薪資給付事由</p>
            <p>9.專業等級分為以下 1 ~ 5級，定義工作的需求以及個人專業程度</p>
            <ul>
              <li>等級1(無: 無任何經驗)</li>
              <li>等級2(初學: 5次以下經驗者)</li>
              <li>等級3(普通: 多經驗者)</li>
              <li>等級4(精通: 出師一步之遙)</li>
              <li>等級5(最專業: 專業職人)</li>
            </ul>                 
            {/* <p>8.雇主須付刊登費，傭兵手續費為該任務之僱主決定，手續費區間為下(單位:NTD):</p> */}
            {/* <ul>
              <li>時薪176 ~200: 5元</li>
              <li>時薪201 ~230: 10元</li>
              <li>時薪231 ~280: 30元</li>
              <li>時薪281 ~350: 50元</li>
              <li>時薪380 ~400: 80元</li>
              <li>時薪401以上: 120元</li>
            </ul> */}
          </div>
      </div>
    </div>
  )
}

export default RulePage;