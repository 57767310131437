import React from 'react'

const PreviewImage = (file) => {
    const fileList = file.file;
    const urls = [];
    for (let i = 0; i < fileList.length; i++) {
        const url = URL.createObjectURL(fileList[i]);
        urls.push(url);
    }
    const previewImg = urls.map((item, i) => 
        <img key={i} src={item} width="300px" height="168.75px"/>
    )
  return (
    <div className="previewImg">
        {previewImg}
    </div>
  )
}

export default PreviewImage