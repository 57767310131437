import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link, Outlet } from 'react-router-dom';
import { signOut } from '../databaseSevices/authenticate';
import { useUserAuth } from '../context/UserAuthContext';
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../config/firebase';
import { useNavigate } from "react-router-dom";
import PreviewImage from './previewImage';
import FooterComponent from './footer_component';
import icon_userchange from "../images/design_after/icon_userchange.svg";
import "./style/sidebar.css";

const Sidebar = () => {
    const { user } = useUserAuth();
    const [avatar, setAvatar] = useState(null);
    const [newAvatar, setNewAvatar] = useState(null);
    const inputAvatar = useRef(null);
    let navigate = useNavigate();

    useEffect(() => {
        getAvatar()
    },[user.id])

    const getAvatar = () => {
        const pathReference = ref(storage, `User_avatar/${user.id}`);
        getDownloadURL(pathReference)
        .then((url) => {
            setAvatar(url)
          })
        .catch(() => {
            setAvatar(null)
        });
    }

    const OpenModel = () => {

        return (
            <>
            <button type="button" class="btn btn-primary" ref={inputAvatar} data-bs-toggle="modal" data-bs-target="#exampleModal" hidden>
            Launch demo modal
            </button>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">變更頭貼</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <input type="file" accept=".jpeg,.png" onChange={(e) => {
                                setNewAvatar(e.currentTarget.files);
                        }}/>
                        {newAvatar && <PreviewImage file={newAvatar}/>}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
                        <button type="button" class="btn btn-primary" onClick={() => handleChangeAvatar(avatar)}>變更</button>
                    </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
    const handleChangeAvatar = async (file) => { 
        compressImg(file);
        await updateDoc(doc(db, "user", user.id), {avatar: file[0].name})
        .then(() => {
            navigate(0);
        })

    }
    const compressImg = (file) => {
        if(file){
          for (let i = 0; i < file.length; i++) {
            let imageType = file[i].type;
            let reader = new FileReader();
            reader.readAsArrayBuffer(file[i]);
            reader.onload = function (event) {
              let blob = new Blob([event.target.result]);  
              window.URL = window.URL || window.webkitURL;
              let blobURL = window.URL.createObjectURL(blob);  
              let image = new Image();
              image.src = blobURL;
              image.onload = function () {
                  let newImage = resize(image, imageType);
                  uploadToFirestorage(newImage, file[i].name);
              }
            }
          }
        }else{
          return false
        }
    }
    const resize = (img, type) => {
        let canvas = document.createElement("canvas");
        canvas.width = img.width * 0.7;
        canvas.height = img.height * 0.7;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width * 0.7, img.height * 0.7); // 把圖片畫在畫布上(0,0)作標到(canvas.width,canvas.height)
        let newImg = canvas.toDataURL(type, 0.8); // 0.8是圖片壓縮比
        return newImg;
    }
    const uploadToFirestorage = (imgURL) => {
        const storageRef = ref(storage, "User_avatar/"+ user.id);
        // Data URL string
        uploadString(storageRef, imgURL, 'data_url')
    }
   return (
    <div className="member">
        <div className="padding-rl d-flex">
            <div className="divider_background">
                <div className="avatar">
                    <OpenModel/>
                    <img src={avatar !== null ? avatar : icon_userchange} width="95px" height="95px" title="更換頭像" onClick={() => {inputAvatar.current.click();}}/>
                    {/* <img src={user.avatarURL} width="95px" height="95px" title="更換頭像" onClick={() => {inputAvatar.current.click();}}/> */}
                    <h3>{user.name}</h3>
                </div>

                <div className="sidebar">
                    <li className="sider_title">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                            </svg>我的
                        </div>
                    </li>
                    <li className="mainly_hoverelement">
                        <Link to={'/member/profile'}>個人資料</Link>
                    </li>
                    <li className="sider_title">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"/>
                            <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
                            </svg>傭兵接案
                        </div>
                    </li>
                    <li className="mainly_hoverelement">
                        <Link to={'/member/myjob'}>工作歷程</Link>
                    </li>
                    <li className="sider_title">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                            <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                            </svg>雇主發案
                        </div>
                    </li>
                    <li className="mainly_hoverelement">
                        <Link to={'/member/mymission'}>提供任務</Link>
                    </li>
                    <li className="mainly_hoverelement mt-3">
                        <Link to="#">設定</Link>
                    </li>
                    <li className="mainly_hoverelement">
                        
                            <button onClick={() => {
                                signOut();
                                window.location.reload();
                            }} color="black">登出</button>
                    </li>
                </div>
            </div>
            <div className="background_content">
                <Outlet/>
            </div>
        </div>
        <div className="background_footer"></div>
    </div>

   )
}
 
export default Sidebar;