import React, { useEffect, useState } from 'react'
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage, db } from '../config/firebase';
import { useUserAuth } from '../context/UserAuthContext';
import { updateMission, getOneMission } from '../databaseSevices/mission';
import { doc, setDoc, updateDoc, getDoc, addDoc, collection } from 'firebase/firestore';
import { useParams, useNavigate} from 'react-router-dom'
import lastbutton from "../images/design_after/lastbutton_welcome.svg";
import nextbutton from "../images/design_after/nextbutton_welcome.svg";
import defaultImg from "../images/design_after/icon-image.svg";
import "../component/style/payment.css";

const PaymentPage = () => {
  const { user } = useUserAuth();
  const { missionID } = useParams("");
  const [ mission, setMission ] = useState([]);
  const [ cubestyle, setCubestyle] = useState(0);
  const [ params, setParams ] = useState([]);
  const [ sent, setSent ] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    FetchMission();
    getList();
  },[])
  
  const FetchMission = async () => {
    const docSnap = await getDoc(doc(db, "mission", missionID));
    if (docSnap.exists()){
      const judge =docSnap.data()?.applicant?.find(x => x.userID === user.id);
      if(judge){
        setSent(true);
      }else{
        setSent(false);
      }
      setMission(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  const getList = () => {
    const listRef = ref(storage, `MissionImgs/${missionID}/`);
    listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          getDownloadURL(itemRef)
            .then((url) => {
              setParams(arr => [...arr, url]);
            })
        });
      }).catch((error) => {
        console.log(error);
        return false
      });
  }

  const addUserToMission = async () => {
    const missionRef = doc(db, "mission", missionID);
    const getOldMission = await getOneMission(missionID);
    const applicant = {
      username: user.username, 
      useremail: user.email,
      userID: user.id,
      userphone: user.phoneNumber,
      userskill: [user.skill1 || null, user.skill2 || null, user.skill3 || null],
      userskillLevel: [user.skillLevel1 || null, user.skillLevel2 || null, user.skillLevel3 || null],
    }

    if(getOldMission.applicant){
      const a = getOldMission.applicant;
      const b = a.push(applicant);
      await setDoc(missionRef, {applicant: a}, { merge: true });
    }else{
      await setDoc(missionRef, {applicant: [applicant]}, { merge: true });
    }
  }

  const addMissionToUser = async () => {
    let newMission = mission;
    // control mission message
    delete newMission.poster_ID;
    delete newMission.applicant;
    delete newMission.timestamp;
    newMission.missionID = missionID;

    const docSnap = await getDoc(doc(db, "user", user.id));
    const userRef = doc(db, "user", user.id);
    if(docSnap.data().applyFor) {
      const ApplyFor = docSnap.data().applyFor;
      const n = ApplyFor.push(newMission);
      await setDoc(userRef, {applyFor: ApplyFor}, { merge: true });
    }else{
      await setDoc(userRef, {applyFor: [newMission]}, { merge: true });
    }
  }
  const sendHireEmail = (email) => {
    addDoc(collection(db, "mail"),{
      to: [email],
      message: {
        subject: '應徵通知!',
        text: '您的任務有新的人應徵',
        html: 
        `<h1>有人應徵您的任務</h1>
          <a href="https://touload.com/member/mymission">前往提供任務</a>`,
      },
    })
  }
  const sendRequest = (email) => {
    addUserToMission()
    .then(() => {
      addMissionToUser();
    })
    .then(() => {
      sendHireEmail(email);
    })
    .then(() => {
      setSent(true);
    })
    .then(() => {
      navigate("/");
    })
    .catch((error) => {
      console.log("fail !");
      console.log(error);
    })

  }
  
  const lastcube = () => {
    if(cubestyle < 0){
      setCubestyle(cubestyle + 700)
    }
  }
  const nextcube = () => {
    if(cubestyle > - (params.length-1)* 700){
      setCubestyle(cubestyle - 700)
    }
  }
  let a;
  if(params.length !== 0){
    a = params.map((val, i) => (
      <img key={i} src={val} height="700px"/>
    ))
  }
  let fee;
  switch (true) {
    case (mission.salary < 176) :
      fee = 0;
      break;
    case (mission.salary < 200):
      fee = 5;
      break; 
    case (mission.salary < 230):
      fee = 10;
      break;
    case (mission.salary < 280):
      fee = 30;
      break;
    case (mission.salary < 350):
      fee = 50;
      break;    
    case (mission.salary < 400):
      fee = 80;
      break; 
    case (mission.salary ):
      fee = 120;
      break; 
  }

  return (
    <div className="payment container">
    <div className="img_payment">
      <div className="welcome_carousel" style={{ 
        transform: `translateX(${cubestyle}px)` 
      }}>
        {a}
      </div>
      {params.length !== 0 && 
        <>
          <img className="button_payment last" src={lastbutton} width="45px" height="45px" onClick={() => {lastcube()}}/>
          <img className="button_payment next" src={nextbutton} width="45px" height="45px" onClick={() => {nextcube()}}/>
        </>
      }
      {params.length == 0 && <img id="defaultImg" src={defaultImg} width="700px"/>}
    </div>
    <div className="text_payment">  
      {/* <button onClick={getList}>clickme</button> */}
      <h2>{mission.title}</h2>
      <table>
        <tbody>
          <tr>
            <td width="30%">地點:</td>
            <td>{mission.location}</td>
          </tr>
          <tr>
            <td>薪資:</td>
            {mission.salaryMethod?
              <td>{mission.pieceRate} NTD/次</td>
            :
              <td>{mission.hourlyRate} NTD/hr</td>
            }
          </tr>
          <tr>
            <td>工作內容:</td>
            <td id='text_payment_description'><p dangerouslySetInnerHTML={{__html: mission.description?.replace(/(\n|\r|\r\n)/g, '<br/>')}} /></td>
          </tr>
          <tr>
            <td>專業等級:</td>
            <td>{mission.profession}</td>
          </tr>
          <tr>
            <td>週期性:</td>
            <td>{mission.period ? "是" : "否"}</td>
          </tr>
          {mission.period &&
            <tr>
              <td>每逢:</td>
              <td>{mission.week?.map((item, i) => {
                return <p key={i}>{item}</p>
              })}</td>
            </tr>
          }
          <tr>
            <td>開始時間:</td>
            <td>{mission.duration?.start}</td>
          </tr>
          <tr>
            <td>結束時間:</td>
            <td>{mission.duration?.end}</td>
          </tr>
          <tr>
            <td>委託人:</td>
            <td>{mission.poster_name}</td>
          </tr>
          <tr>
            <td>應徵手續費:</td>
            <td>試用期免費</td>
            {/* <td>{fee} NTD</td> */}
          </tr>
          <tr>
            <td>備註:</td>
            <td><p dangerouslySetInnerHTML={{__html: mission.remark?.replace(/(\n|\r|\r\n)/g, '<br/>')}} /></td>
          </tr>
        </tbody>
      </table>
      {sent === false && <button className="btn btn-primary" type='button' onClick={() => {sendRequest(mission.poster_email)}} disabled={mission.poster_ID === user.id ? true : false}>送出請求</button>}
      {sent && <button className="btn btn-warning" type='button' disabled>已送出</button>}
    </div>
  </div>
  )
}

export default PaymentPage