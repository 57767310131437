import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthContext';
import { db } from '../config/firebase';
import { doc, collection, getDoc, getDocs, query, where, updateDoc, addDoc } from "firebase/firestore";
import icon_clock from "../images/design_after/icon_clock.svg";
import icon_location from "../images/design_after/icon_location.svg";
import icon_chat from "../images/design_after/icon_chat.svg";   
import icon_lastpage from "../images/design_after/icon_lastpage.svg";
import icon_nextpage from "../images/design_after/icon_nextpage.svg";
import "../component/style/myjob.css";

const MyjobPage = () => {
  const { user } = useUserAuth();
  let navigate = useNavigate();
  let [ mission, setMission ] = useState([]);
  let [pages, setpages] = useState(1);

  useEffect(() => {
    FetchJob()
    .then((res) => {
      setMission(res);
    })
    .catch((err) => {
      setMission(false);
    })
  },[user.id])

  const FetchJob = async () => {
    const docSnap = await getDoc(doc(db, "user", user.id));
    if(docSnap.data().applyFor){
      return (docSnap.data().applyFor);
    }else{
      return false
    }
  } 

  const sendNotifyEmail = (email) => {
    addDoc(collection(db, "mail"),{
      to: [email],
      message: {
        subject: '邀請狀態通知!',
        text: '您的邀請的傭兵回覆您',
        html: 
        `<h1>回覆您的任務邀請</h1>
          <a href="${window.location.origin}/member/mymission">前往提供任務</a>`,
      },
    })
  }
  const creatChatRoom = async (mid) => {
    const docSnap = await getDoc(doc(db, "mission", mid));
    const posterName = docSnap.data().poster_name;
    const posterID = docSnap.data().poster_ID;
    let arr = [];
    // Always Boss first [BossID, ApplicantID]
    const q = query(collection(db, "chat"),(where('memberID', 'in', [[posterID, user.id]]),
      where('memberID', 'in', [[posterID, user.id]]))
    );
    const querySnapshot1 = await getDocs(q);
    querySnapshot1.forEach((doc) => {
      arr.push(doc.id);
    });
    if(arr.length > 0){
      // open the chat
      alert(`${posterName}已加入`)
    }else{
      await addDoc(collection(db, "chat"), {
        memberName:[posterName, user.username],
        memberID: [posterID, user.id],
        conversation: [],
      });
      alert(`已將${posterName}加入您的聊天室`)
    }
  }

  const DecisionButton = (props) => {
    const isMID = props.MID;
    const bossEmail = props.bossEmail;
    const [myjob, setMyjob] = useState({});
    const [othersjob, setOthersjob] = useState({});
    const [yesHidden, setYesHidden] = useState(false);
    const [noHidden, setNoHidden] = useState(false);

    useEffect(() => {
      fetchMission()
      .then((res) => {
        setMyjob(res.judgement);
        setOthersjob(res.others);
      })
      .catch((err) => {
        console.log(err);
        setMyjob(false);
      })
    },[])
    const fetchMission = async () => {
      const docSnap = await getDoc(doc(db, "mission", isMID));
      if(docSnap.data()){
        const judgement = docSnap.data().applicant?.find((item) => 
          item?.userID === user.id
        )
        const others = docSnap.data().applicant?.find((item) => 
          item?.userID !== user.id
        ) || null
        const applicants = {
          judgement: judgement,
          others: others,
        };
        return applicants
      }else{
        return false
      }
    }

    const updateInvitation = async (reply) => {
      myjob.invitation = reply;
      let appli = []
      appli.push(myjob);
      if(othersjob){
        appli.push(othersjob);
      }
      await updateDoc(doc(db, "mission", isMID), {
        applicant: appli,
      });
      sendNotifyEmail(bossEmail);
      alert("已回覆雇主！")
    }

    return (
      <>
        {myjob?.invitation === "no-reply" && 
          <div className="dicisionButton">
            <button id="dicisionButton_yes" value="yes" onClick={(e) =>{updateInvitation(e.target.value); setNoHidden(true)}} hidden={yesHidden} >接受</button>
            <button id="dicisionButton_no" value="no" onClick={(e) =>{updateInvitation(e.target.value); setYesHidden(true)}} hidden={noHidden} >拒絕</button>
          </div>
        }
        {myjob?.invitation === "yes" && 
          <div className="dicisionButton">
            <button id="dicisionButton_yes" disabled>接受</button>
          </div>
        }
        {myjob?.invitation === "no" && 
          <div className="dicisionButton">
            <button id="dicisionButton_no" disabled>拒絕</button>
          </div>
        }
      </>
    )
  }
  const allpages = (mission.length % 6 === 0 ) ? (Math.floor(mission.length/6)) : (Math.floor(mission.length/6) +1);
  const pagesbtn = [];
  for (let index = 1; index <= allpages; index++) {
      // pagesbtn.push(<button className="pagebtns" onClick={changepages(index)}>{index}</button>)
      pagesbtn.push(index);
  }
  const eachpage = pagesbtn.map((n, i) => {
    if(pages !== n){
      return <button key={i} onClick={() => setpages(n)} >{n}</button>
    }});
  const renderMission = mission ? (mission?.slice((pages-1)*6, (pages*6))) : null
  const particalMission = renderMission?.map((element,i) => {
    if(new Date() > new Date(element.take_down.seconds*1000)){
      return (
        <div className="applyjob expired" key={i}>
          <div div className="text">
            <h5>{element.title}<span style={{color: "red"}}>   (已過期)</span></h5>
            <div className="job_catergory"><p>{element.poster_name} | {element.category}</p></div>
            {element.salaryMethod?
              <div className="salary"><span>NT$ {element.pieceRate}/次</span></div>
            :
              <div className="salary"><span>NT$ {element.hourlyRate}/時</span></div>
            }
            <div className="berow"><img src={icon_clock} width="16px" height="16px" /><p>{element.duration?.start.replace(/-/g, "/")} ~ {element.duration?.end.slice(5).replace(/-/g, "/")}</p></div>
            <div className="berow"><img src={icon_location} width="16px" height="16px"/><p>{element.location}</p></div>
          </div>
          <div className="btn-sec">
            <DecisionButton MID={element.missionID} key={i}/>
            <button id="showmission" disabled>查看任務</button>
            <button id="showapplicant" disabled><img src={icon_chat} width="16px" height="16px"/>聯繫雇主</button>
          </div>
          
        </div>
      )
    }else{
      return (
        <div className="applyjob" key={i}>
          <div div className="text">
            <h5>{element.title}{new Date() > new Date(element.take_down.seconds*1000) && <span style={{color: "red"}}>   (已過期)</span>}</h5>
            <div className="job_catergory"><p>{element.poster_name} | {element.category}</p></div>
            {element.salaryMethod?
              <div className="salary"><span>NT$ {element.pieceRate}/次</span></div>
            :
              <div className="salary"><span>NT$ {element.hourlyRate}/時</span></div>
            }
            <div className="berow"><img src={icon_clock} width="16px" height="16px" /><p>{element.duration?.start.replace(/-/g, "/")} ~ {element.duration?.end.slice(5).replace(/-/g, "/")}</p></div>
            <div className="berow"><img src={icon_location} width="16px" height="16px"/><p>{element.location}</p></div>
          </div>
          <div className="btn-sec">
            <DecisionButton MID={element.missionID} bossEmail = {element.poster_email} key={i}/>
            <button id="showmission" onClick={() => {
              navigate(`/callback/${element.missionID}`
            )}}>查看任務</button>
            <button id="showapplicant" onClick={() => {creatChatRoom(element.missionID)}}><img src={icon_chat} width="16px" height="16px"/>聯繫雇主</button>
          </div>
          
        </div>
      )
    }
  }
);
  return (
    <div className="myjob">
      <h3>現正應徵的任務</h3>
      <hr/>
      {mission ?(
        <div className="grid">
          {particalMission}
        </div>
      ):(
        <h4>您目前尚應徵任何任務</h4>
    )}
  </div>
  )
}
export default MyjobPage