// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFirestore } from 'firebase/firestore';
import { getMessaging } from "firebase/messaging";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyATKrK7KmD5bVJ67O8WjjrYNFFKKmY9QZc",
  authDomain: "touload.firebaseapp.com",
  projectId: "touload",
  storageBucket: "touload.appspot.com",
  messagingSenderId: "951506043383",
  appId: "1:951506043383:web:85c8e56291e597e589aef2",
  measurementId: "G-TKJ2LDR1C0"
};

// // Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const user = auth.currentUser;
export const messaging = getMessaging(app);

