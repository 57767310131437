import React, { useState } from 'react'
import defaultImg from "../images/design_after/icon-image.svg";
import lastbutton from "../images/design_after/lastbutton_welcome.svg";
import nextbutton from "../images/design_after/nextbutton_welcome.svg";
import img_example1 from "../images/design_after/jobtype1.jpg";
import img_example2 from "../images/design_after/jobtype2.jpg";
import img_example3 from "../images/design_after/jobtype3.jpg";

import "../component/style/payment.css";
const TestUIPage = () => {
    let [send, setSend] = useState(false);
    let [cubestyle, setCubestyle] = useState(0);

    const lastcube = () => {
        if(cubestyle < 0){
          setCubestyle(cubestyle + 700)
        }
      }
    const nextcube = () => {
        if(cubestyle > - 2*700){
            setCubestyle(cubestyle - 700)
        }
    }

    return (
    <div className="payment">
    <div className="img_payment">
        <div className="welcome_carousel" style={{ 
        transform: `translateX(${cubestyle}px)` 
        }}>
            <div style={{display: "flex"}}>
                <img src={img_example1}/>
                <img src={img_example2}/>
                <img src={img_example3}/>
            </div>
        </div>
        <>
            <img className="button_payment last" src={lastbutton} width="45px" height="45px" onClick={() => {lastcube()}}/>
            <img className="button_payment next" src={nextbutton} width="45px" height="45px" onClick={() => {nextcube()}}/>
        </>
        {/* <img id="defaultImg" src={defaultImg} width="700px"/> */}
    </div>
    <div className="text_payment">  
        {/* <button onClick={getList}>clickme</button> */}
        <h2>範例－幫我打蟑螂</h2>
        <table>
        <tbody>
            <tr>
            <td width="30%">地點:</td>
            <td>台北市中正區仁愛路一段一號一樓</td>
            </tr>
            <tr>
            <td>薪資:</td>
            <td>300 NTD/hr</td>
            </tr>
            <tr>
            <td>工作內容:</td>
            <td id='text_payment_description'>
                <p>1. 網頁設計</p>
                <p>2. 上班睡覺</p>
                <p>3. 吃飯打球</p>
                <p>4. 打東東打東東打東東打東東打東東打東東打東東打東東</p>
                <p>5. 打東東打東東打東東打東東打東東打東東打東東打東東</p>
                <p>6. 打東東打東東打東東打東東打東東打東東打東東打東東</p>
            </td>
            </tr>
            <tr>
            <td>週期性:</td>
            <td>是</td>
            </tr>
            <tr>
            <td>每逢:</td>
            <td>
                <p>星期一</p>
                <p>星期三</p>
                <p>星期日</p>
            </td>
            </tr>
            <tr>
            <td>開始時間:</td>
            <td>2023/01/01 06:00</td>
            </tr>
            <tr>
            <td>結束時間:</td>
            <td>2023/01/02 18:15</td>
            </tr>
            <tr>
            <td>委託人:</td>
            <td>楊千又</td>
            </tr>
            <tr>
            <td>應徵手續費:</td>
            <td>試用期免費</td>
            {/* <td>{fee} NTD</td> */}
            </tr>
            <tr>
            <td>備註:</td>
            <td>
                <p>現金支付</p>
                <p>結束後付款</p>
                <p>超過時間有支付加班費</p>
            </td>
            </tr>
        </tbody>
        </table>
        <button className={send ? "btn btn-warning" : "btn btn-primary"} type='button' onClick={() => {setSend(true)}}>{send ? "已送出" : "送出請求"}</button>
    </div>
    </div>
    )
}

export default TestUIPage