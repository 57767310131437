import { db } from '../config/firebase';
import { doc, collection, addDoc, getDoc, getDocs, updateDoc, deleteDoc, serverTimestamp, query, orderBy, limit, startAfter } from "firebase/firestore";
import { QueryDocumentSnapshot, DocumentData, FieldValue, Timestamp } from 'firebase/firestore'

type duration = {
    start: Timestamp,
    end: Timestamp,
}

type timestamp = {
    lastUpate: FieldValue, //最後修改時間, 會自動修改,不需輸入(使用伺服器端時間
    create: FieldValue //註冊時間, 會自動加上,不需輸入(使用伺服器端時間)
}

type mission = {
    poster: string, //建立者
    level: string, //任務級別
    duration: duration,
    title: string, //標題
    discription: string, //內容說明
    location: string, //工作位置
    salary: number, //薪水
    remark: [string], //備註
    tag: [string], //標籤
    timestamp: timestamp | undefined,
    [key: string]: any,
}



/**
 * 查詢單一任務
 * @param {*} missionId 欲查詢的任務ID
 * @returns 
 */
async function getOneMission(missionId: string) {
    const docRef = doc(db, 'mission', missionId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        return NaN
    }
}

/**
 * 批次取得
 * @param {*} filter 
 * @param {*} limit_number
 * @returns `querySnapshot`  
 * `querySnapshot.docs()` 可以取得Array 
 * 
 * 使用範例
 * querySnapshot.forEach((doc) => {            
 * console.log('data', doc.data())
 * console.log('document id', doc.id)
 * });
 */
async function getBatchMission(limit_number: number) {
    const collectionRef = collection(db, 'mission');
    const q = query(collectionRef, orderBy("timestamp.lastUpate"), limit(limit_number));
    return getDocs(q);
}

/**
 * 取得下一個batch的任務資料  
 * 用於infinity scroll或是換頁的時候  
 * @param previous_documentSnapshots 請傳入前一次的querySnapshot.docs
 * @param {*} filter 
 * @param {*} limit_number 
 * @returns 
 * `querySnapshot`  
 * `querySnapshot.docs` 可以取得Array 
 * ff
 * TO-DO 支援filter ex 薪水 > ???
 * TO-DO 支援多種排序 ex 薪水 小到大 or 大到小
 */
async function getNextBatchMission(previous_documentSnapshots: QueryDocumentSnapshot<DocumentData>[], limit_number: number = 10) {

    if (previous_documentSnapshots.length < 1) return getBatchMission(limit_number = limit_number)

    const collectionRef = collection(db, 'mission');
    const lastVisible = previous_documentSnapshots[previous_documentSnapshots.length - 1];
    const q = query(collectionRef, orderBy("timestamp.lastUpate"), startAfter(lastVisible), limit(limit_number));
    return getDocs(q);
}

async function addMission(missionData: mission) {
    const docData = { ...missionData }
    docData.timestamp = {
        lastUpate: serverTimestamp(),
        create: serverTimestamp(),
    }
    const docRef = addDoc(collection(db, 'mission'), docData)
    return docRef
}

function checkModifiedPart(upperKeyStr: string = '', origin: any, modified: any) {
    let docData: { [key: string]: any } = {}

    Object.keys(origin).forEach((key) => {
        let value = origin[key]
        let modifiedValue = modified[key]

        if (Array.isArray(value)) {
            // Array
            /* WARNING: arrays must not contain {objects} or behavior may be undefined */
            if (JSON.stringify(value) !== JSON.stringify(modifiedValue)) {
                docData[upperKeyStr + key] = modifiedValue
            }
        } else if ((typeof value === 'object') && (value !== null)) {
            // Dict
            let lowwerDocData = checkModifiedPart(key + '.', value, modifiedValue);
            docData = { ...docData, ...lowwerDocData };
        } else {
            if (value !== modifiedValue) {
                docData[upperKeyStr + key] = modifiedValue
            }
        }
    })

    return docData
}

async function updateMission(missionId: string, origin: mission, modified: mission) {
    // compare
    let docData = checkModifiedPart('', origin, modified)
    docData["timestamp.lastUpate"] = serverTimestamp()
    
    return updateDoc(doc(db, 'mission', missionId), docData).then(
        (res) => { return true },
        (error) => { return false }
    )
}

/**
 * 刪除單一任務
 * @param {*} missionId 欲刪除的文件ID
 * @returns 
 */
async function deleteMission(missionId: string) {
    return deleteDoc(doc(db, 'mission', missionId)).then(
        (res) => { return true },
        (error) => { return false }
    )
}


export {
    getOneMission,
    getBatchMission,
    getNextBatchMission,
    addMission,
    updateMission,
    deleteMission
};