import { db } from '../config/firebase';
import { doc, setDoc, getDoc, updateDoc, deleteDoc, Timestamp, serverTimestamp, FieldValue } from "firebase/firestore";

type tag = {
    skill: string[] | never[]
}

type profile = {
    email: string | null,
    phone: string | null,
    tel: string | null,
    identity: string | null,
    birth: Timestamp | null
}

type timestamp = {
    lastUpate: FieldValue,
    registration: FieldValue
}

type member = {
    skill: string[] | never[],
    license: string[] | never[],
    expertise: string[] | never[],
    tag: tag,
    profile: profile,
    role: string,
    timestamp: timestamp | undefined,
    [key: string]: any,
}



async function getMember(uid: string) {
    const docRef = doc(db, "user", uid);
    return await getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) return docSnap.data();
        else return null;
    })
}

/**
 * 新增使用者個人資料
 * @param {*} uid 使用者 ID
 * @param {*} memberdata 資料，格式請參考說明
 * @returns 
 * `true` - 新增成功  
 * `false` - 新增失敗
 */
async function addMember(uid: string, memberdata: member) {
    const docData = { ...memberdata }
    docData.timestamp = {
        lastUpate: serverTimestamp(),
        registration: serverTimestamp(),
    }

    return setDoc(doc(db, 'user', uid), docData, { merge: true }).then(
        (res) => { return true },
        (error) => { return false }
    )

}

function checkModifiedPart(upperKeyStr = '', origin: any, modified: any) {
    let docData: { [key: string]: any } = {}

    Object.keys(origin).forEach((key) => {
        let value = origin[key];
        let modifiedValue = modified[key];

        if (Array.isArray(value)) {
            // Array
            if (JSON.stringify(value) !== JSON.stringify(modifiedValue)) {
                docData[upperKeyStr + key] = modifiedValue
            }
        } else if ((typeof value === 'object') && (value !== null)) {
            // Dict
            let lowwerDocData = checkModifiedPart(key + '.', value, modifiedValue);
            docData = { ...docData, ...lowwerDocData };
        } else {
            if (value !== modifiedValue) {
                docData[upperKeyStr + key] = modifiedValue
            }
        }
    })

    return docData
}

async function updateMember(uid: string, origin: member, modified: member) {
    // compare
    let docData = checkModifiedPart('', origin, modified)

    docData["timestamp.lastUpate"] = serverTimestamp()
    console.log(docData)
    return updateDoc(doc(db, 'user', uid), docData).then(
        (res) => { return true },
        (error) => { return false }
    )
}

async function deleteMember(uid: string) {
    return deleteDoc(doc(db, "user", uid)).then(
        (res) => { return true },
        (error) => { return false }
    )
}


export {
    addMember,
    getMember,
    deleteMember,
    updateMember
};