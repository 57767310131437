import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { db, storage } from '../config/firebase';
import { ref, uploadBytesResumable, getDownloadURL, uploadString } from "firebase/storage";
import { doc, setDoc, getDoc, query, orderBy, limit, collection, getDocs, addDoc, documentId } from "firebase/firestore";
import { getMember } from "../databaseSevices/member"
import { useUserAuth } from '../context/UserAuthContext';
import "../component/style/mission.css"
import { addMission } from "../databaseSevices/mission";
import PreviewImage from '../component/previewImage';
import { useNavigate } from 'react-router-dom';

const MissionFormPage = () => {
  const [textarea, setTextarea] = useState("");
  const [memo, setMemo] = useState("");
  //驗證表單
  const today = new Date();
  const minday = today.setHours(today.getHours() + 1);
  const validate = Yup.object().shape({
    jobname: Yup.string()
    .min(2, "至少2字")
    .required("必填!"),
    hourlyRate: Yup.number()
    .min(176, "不得低於最低工資"),
    timeStartDate: Yup.date()
    .required("必填!")
    .min(new Date(minday), "至少一小時前"),
    timeEndDate: Yup.date()
    .required("必填!")
    .min(Yup.ref('timeStartDate'), "起訖日期錯誤"),
    place: Yup.string()
    .required("必填!"),
    file: Yup.mixed()
    .nullable()
    .test('fileFormat', '限PDF、PNG、JPG', (value) => {
      try {
        if(value){
          const limitType = ['application/pdf', 'image/jpeg', 'image/png'];
          let totalType = [];
          for (let i = 0; i < value.length; i++) {
            totalType.push(value[i].type);
          }
            const multipleExist = totalType.every(value => {
              return limitType.includes(value);
            });
          return multipleExist
        }else{
          return true
        }
      } catch (error) {
        console.log(error);
      }  

    })
    .test("fileSize", "總檔案須小於16MB", (value) => {
      let totalsize = 0;
      try {
        if(value){
          for (let i = 0; i < value.length; i++) {
            totalsize = totalsize + value[i].size;
          }
          if(totalsize/1024/1024 > 16){
            value = false
          }else{
            value = true
          }
          return value
        }else{
          return true
        }
      } catch (error) {
        console.log(error);  
      }
    })
  });

const compressImg = (file, mid) => {
  if(file){
    for (let i = 0; i < file.length; i++) {
      let imageType = file[i].type;
      let reader = new FileReader();
      reader.readAsArrayBuffer(file[i]);
      reader.onload = function (event) {
        let blob = new Blob([event.target.result]);  
        window.URL = window.URL || window.webkitURL;
        let blobURL = window.URL.createObjectURL(blob);  
        let image = new Image();
        image.src = blobURL;
        image.onload = function () {
            let newImage = resize(image, imageType);
            uploadToFirestorage(newImage, mid, file[i].name);
        }
      }
    }
  }else{
    return false
  }
}
const resize = (img, type) => {
  let canvas = document.createElement("canvas");
  canvas.width = img.width * 0.7;
  canvas.height = img.height * 0.7;
  let ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, img.width * 0.7, img.height * 0.7); // 把圖片畫在畫布上(0,0)作標到(canvas.width,canvas.height)
  let newImg = canvas.toDataURL(type, 0.8); // 0.8是圖片壓縮比
  return newImg;
}
const uploadToFirestorage = (imgURL, mid, name) => {
  console.log(mid);
  const storageRef = ref(storage, `MissionImgs/${mid}/${name}`);
  // Data URL string
  uploadString(storageRef, imgURL, 'data_url')
}

const calculatDate = (x) => {
  let today = new Date();
  Date.prototype.addDays =function(days){
    this.setDate(this.getDate() + days);
    return this; 
  }
  let d;
  switch (x) {
    case "試用期免費(上架5日)":
      d = 5;
      break;
    case "試用期免費(上架10日)":
      d = 10;
      break;
    case "試用期免費(上架30日)":
      d = 30;
      break;
    case "60天(180/D)":
      d = 60;
      break;
    case "80天以上(80/D)":
      d = 80;
      break;    
    case "緊急任務(試用期免費 上架24小時)":
      d = 1;
      break; 
  }
  const takeOffDate = today.addDays(d);
  return takeOffDate
}
const handleWeek = () => {
  let list = [];
  let x = document.querySelectorAll("[type=checkbox]:checked");
  for (var i = 1; i < x.length; i++) {
    list.push(x[i].value);
  }
  return list
}
const handleFile = (inputfile) => {
  const fileNameList = [];
  var file;
  if(inputfile){
    for (var i = 0; i < inputfile.length; i++) {
      // file = inputfile.item(i);
      file = inputfile[i];
      fileNameList.push(file.name);
    }
    return fileNameList
  }else{
    return null
  }
}

let navigate = useNavigate();
const { user } = useUserAuth();
const [howpay, setHowpay] = useState(false);
const [period, setPeriod] = useState(false);
const [alert, setAlert] = useState(false);
const [formlock, setFormlock] = useState(false);

const addMinssionToUser = async (x, MID) => {
  const getOldData = await getMember(user.id);
  const userRef = doc(db, "user", user.id);
  if (getOldData.provideMission){
    const a = getOldData.provideMission;
    const b = a.push(x) ;
    setDoc(userRef, {provideMission:a}, { merge: true });
    return MID
  }else{
    setDoc(userRef,{provideMission:[x]}, { merge: true });
    return MID
  }
}

const sendEmail = (email) => {
  addDoc(collection(db, "mail"),{
    //test email!!
    to: [email],
    message: {
      subject: '工作成立通知!',
      text: '您的任務已成功成立',
      html: 
      `<h1>您的任務已成功成立</h1>
        <a href="${window.location.origin}/">前往Touload</a>`,
    },
  })
}

//提交表單
const handleSubmit = (values) => {
  let data ={
    poster_name: user.username,
    poster_email: user.email,
    poster_ID: user.id,
    level: values.values.pricing,
    duration:{ start: values.values.timeStartDate.replace(/T/g, "  "),
               end: values.values.timeEndDate.replace(/T/g, "  ")
    },
    description: textarea,
    title: values.values.jobname,
    category: values.values.category,
    profession: values.values.profession,
    salaryMethod: howpay,
    hourlyRate: values.values.hourlyRate,
    pieceRate: values.values.pieceRate,
    period: period,
    week: handleWeek(),
    location:values.values.place,
    remark: memo,
    take_down: calculatDate(values.values.pricing), 
    file: handleFile(values.values.file),
  }
  addMission(data)
  .then((res) => {
    data.missionId = res.id;
    return addMinssionToUser(data, res.id);
  }) 
  .then((mid) => {
    return compressImg(values.values.file, mid);
   })  
  .then(() => {
    return sendEmail(user.email);
   })  
  .then(() => {
    navigate("/");
  })
  .catch((error) => {
    const errorMessage = error.message;
    console.log(errorMessage);
    alert("建立失敗")
  });

//UI
};
  return (
    <Formik 
      initialValues={{
        jobname:"",
        pricing:"試用期免費(上架5日)",
        category:"商業",
        description: "",
        profession: "1(無: 無任何經驗)",
        hourlyRate:"",
        pieceRate:"",
        period: null,
        timeStartDate: "",
        timeEndDate: "",
        week:"",
        takeOff:"",
        place:"",
        memo:"",
        file: null,
      }}
      validationSchema={validate}
      // validateOnChange={true}
      // validateOnBlur={true}
      onSubmit={
        (values) => {
        handleSubmit({values});        
      }}
    >

     {({values, setFieldValue}) => {
      return(
        <div className='container'>
          <Form class= "form" >
              <h1>建立任務</h1>
              <div>
                職缺名稱&nbsp;&nbsp;
                <div style={{color:"red"}}>
                  <ErrorMessage class='errorMessage' name='jobname' />
                </div>
                <Field class="form-control" name="jobname" type="text" disabled={formlock}/>
              </div>
              <br/>
              <div>
                方案
                <Field class="form-select" name="pricing" as="select" disabled={formlock}>
                  <option>試用期免費(上架5日)</option>
                  <option>試用期免費(上架10日)</option>
                  <option>試用期免費(上架30日)</option>
                  {/* <option>試用期免費(上架60日)</option> */}
                  <option>緊急任務(試用期免費 上架24小時)</option>
                </Field>
              </div>
              <br/>
              <div>
                職缺種類
                <Field class="form-select" name="category" as="select" disabled={formlock}>
                  <option>商業</option>
                  <option>工程</option>
                  <option>服務業</option>
                  <option>其他</option>
                </Field>
              </div>
              <br/>
              <div>
              工作內容&nbsp;&nbsp;
              <div style={{color:"red"}}>
                <ErrorMessage class='errorMessage' name='discription'/>
              </div>
              <textarea class="form-control" name="discription" placeholder="主要工作內容、須完成事項" value={textarea} disabled={formlock} onChange={e => setTextarea(e.target.value)}/>
              </div>
              <br/>
              <div>
                專業等級
                <Field class="form-select" name="profession" as="select" disabled={formlock}>
                  <option>1(無: 無任何經驗)</option>
                  <option>2(初學: 5次以下經驗者)</option>
                  <option>3(普通: 多經驗者)</option>
                  <option>4(精通: 出師一步之遙)</option>
                  <option>5(最專業: 專業職人)</option>
                </Field>
              </div>
              <br/>
              <div>
                薪資(NTD)<br/>
                <div class="form-check form-switch" style={{margin:"15px 0"}}>
                  <input class="form-check-input" type="checkbox" name="hourlyRatemethod" role="switch" id="flexSwitchCheckDefault" value={howpay} onClick={() => {setHowpay(!howpay)}} disabled={formlock}></input>
                  <label class="form-check-label" for="flexSwitchCheckDefault">計算方式</label>
                </div>
              </div>
              {!howpay && 
              <>
                /小時&nbsp;&nbsp;
                <div style={{color:"red"}}>
                  <ErrorMessage class='errorMessage' name='hourlyRate'/>
                </div>
                <Field class="form-control" name="hourlyRate" type="number" placeholder="不得低於基本薪資" disabled={formlock}/>
              </>
              }
              {howpay && 
                <>
                  /次&nbsp;&nbsp;
                  <div style={{color:"red"}}>
                    <ErrorMessage class='errorMessage' name='pieceRate'/>
                  </div>
                  <Field class="form-control" name="pieceRate" type="number" placeholder="建議不低於基本薪資" disabled={formlock}/>
                </>
              }
              <br/>
              時間:
              <div class="form-check form-switch" style={{margin:"15px 0"}}>
                <input class="form-check-input" type="checkbox" name="period" role="switch" id="flexSwitchCheckDefault" value={period} onClick={() => {setPeriod(!period)}} disabled={formlock}></input>
                <label class="form-check-label" for="flexSwitchCheckDefault">週期性</label>
              </div>
              {period &&
                <div className="week" name="week" >
                  <label><input type="checkbox" value="Monday" className="checkbox_week"/><span>星期一</span></label>
                  <label><input type="checkbox" value="Tuesday" className="checkbox_week"/><span>星期二</span></label>
                  <label><input type="checkbox" value="Wednesday" className="checkbox_week"/><span>星期三</span></label>
                  <label><input type="checkbox" value="Thursday" className="checkbox_week"/><span>星期四</span></label>
                  <label><input type="checkbox" value="Friday" className="checkbox_week"/><span>星期五</span></label>
                  <label><input type="checkbox" value="Saturday" className="checkbox_week"/><span>星期六</span></label>
                  <label><input type="checkbox" value="Sunday" className="checkbox_week"/><span>星期日</span></label>
                </div>
              }
              <div className="time_form" style={{color:"red"}}>
                  <ErrorMessage class='errorMessage' name='timeStartDate'/>&nbsp;&nbsp;
                  <ErrorMessage class='errorMessage' name='timeEndDate'/>&nbsp;&nbsp;
              </div>
              <div className="time_form">
                <Field class="form-control col-sm" name="timeStartDate" type="datetime-local" disabled={formlock}/>
                <span style={{padding:"0 20px", fontSize:"24px"}}>~</span>
                <Field class="form-control col-sm" name="timeEndDate" type="datetime-local" disabled={formlock}/>
              </div>

              <br/>
              <div>
              地點&nbsp;&nbsp;
              <div style={{color:"red"}}>
                <ErrorMessage class='errorMessage' name='place'/>
              </div>
              <Field class="form-control" name="place" type="text" placeholder="ex:台北市忠孝東路一段一號" disabled={formlock}/>
              </div>
              <br/>
              <div>
                工作區域照片(可複選)&nbsp;&nbsp;
                <div style={{color:"red"}}>
                  <ErrorMessage class='errorMessage' name='file'/>
                </div>
                <input class="form-control" name="file" type="file" placeholder="大小須小於1GB" accept=".jpeg,.pdf,.png" disabled={formlock} multiple onChange={(event) => {
                  setFieldValue("file", event.currentTarget.files);
                }} />
              <br/>
                { values.file && <PreviewImage file={ values.file }/>}
              </div>
              <br/>
              <div>
              備註&nbsp;&nbsp;
              <div style={{color:"red"}}>
                <ErrorMessage class='errorMessage' name='memo'/>
              </div>
              <textarea class="form-control" name="memo" placeholder="薪資總額、支付方式。特殊專業、需求" onChange={e => setMemo(e.target.value)} disabled={formlock}/>
              </div>
              <br />
              <br />
              <div className='form-btn'>
                {alert && <div class="alert alert-danger" role="alert">
                  為安全考量，不建議您釋出極為重要和專業的任務。例如：出納人員、資安人員等。
                </div>}
                {formlock == false && <button style={{background:"#D59545" }} className="btn button_form" onMouseOver={() => {setAlert(true)}} onClick={() => {setFormlock(true); window.alert("任務建立後不得進行修改！！！")}}>建立任務</button>}
                {formlock && <button style={{background:"#322C26" }} className="btn button_form" onClick={() => {setFormlock(false)}}>修改</button>}
                {formlock && <button style={{background:"#7A2727" }} className="btn button_form" type='submit'>確認提交</button>}
              </div>
              
            </Form>
        </div>
      )}}
    </Formik>
  );
}

export default MissionFormPage;