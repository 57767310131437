import React,{ useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useUserAuth } from '../context/UserAuthContext';
import * as Yup from "yup";
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'; 
import { doc ,setDoc } from 'firebase/firestore';
import { auth, db, storage } from '../config/firebase';
import { ref, uploadBytesResumable, getDownloadURL, uploadString } from "firebase/storage";
// import "../component/style/form.css";
import FooterComponent from '../component/footer_component';
import PreviewImage from '../component/previewImage';

//驗證表單
const validate = Yup.object().shape({
  username: Yup.string()
  .min(2, "至少2字")
  .required("必填!"),
  birth: Yup.mixed()
  .required("必填!"),
  phoneNumber: Yup.number()
  .min(10, "請輸入正確格式")
  .required("必填!"),
  email: Yup.string()
  .email("無效的Email")
  .required("必填!"),
  password: Yup.string().min(6, "密碼需6位數以上").required("必填!"),
  passwordConfirm: Yup.string().when('password', (password, schema) => {
    try {
      return password ? schema.oneOf([password], '密碼錯誤').required() : schema   
    } catch (error) {
      alert(error)
    }
  })
});

const compressImg = (file, uid) => {
  if(file){
    let imageType = file.type;
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = function (event) {
      let blob = new Blob([event.target.result]);  
      window.URL = window.URL || window.webkitURL;
      let blobURL = window.URL.createObjectURL(blob);  
      let image = new Image();
      image.src = blobURL;
      image.onload = function () {
          let newImage = resize(image, imageType);
          uploadToFirestorage(newImage, uid);
      }
    }
  }else{
    return false
  }
}
const resize = (img, type) => {
  let canvas = document.createElement("canvas");
  canvas.width = img.width * 0.7;
  canvas.height = img.height * 0.7;
  let ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, img.width * 0.7, img.height * 0.7); // 把圖片畫在畫布上(0,0)作標到(canvas.width,canvas.height)
  let newImg = canvas.toDataURL(type, 0.8); // 0.8是圖片壓縮比
  return newImg;
}
const uploadToFirestorage = (imgURL, uid) => {
  console.log(uid);
  const storageRef = ref(storage, `User_avatar/${uid}`);
  // Data URL string
  uploadString(storageRef, imgURL, 'data_url')
}

const RegisterPage = () => {
  let navigate = useNavigate();
  const { user } = useUserAuth();
  let [lock, setLock] = useState(false);
  if(user){
    navigate("/");
  }
  //提交表單
  const Submit = (obj) => {
    let email = obj.values.email;
    let password = obj.values.password;
    // AuthenticationEmail(email);
    createUserWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      const user = userCredential.user;
      const id = user.uid;
      var today = new Date();
      if(obj.values.file !== null){
        await setDoc(doc(db, "user", id), {
          Authenticate: user.emailVerified, 
          id: id,
          role: obj.values.role,
          email: obj.values.email,
          phoneNumber: obj.values.phoneNumber,
          birth: obj.values.birth,
          username:obj.values.username,
          signupDate: today,
          avatar: obj.values.file.name ,
        });
        compressImg(obj.values.file, id);
      }else{
        setDoc(doc(db, "user", id), {
          Authenticate: user.emailVerified,
          email: obj.values.email,
          id: id,
          role: obj.values.role,
          phoneNumber: obj.values.phoneNumber,
          birth: obj.values.birth,
          username:obj.values.username,
          signupDate: today,
          avatar: null,
        });
      }
    })
    .then(() => {
      return sendEmailVerification(auth.currentUser)
    })
    .then(() => {
      return navigate("/auth");
    })
    .catch((error) => {
      const errorMessage = error.message;
      console.log(errorMessage);
      alert("註冊失敗!!")
    });
  };
  return (
    <div className='container'>
    <Formik 
      initialValues={{
        role:"自然人",
        username:"",
        age:"",
        phoneNumber:"",
        email:"",
        password:"",
        file: null,
      }}
      validationSchema={validate}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={async (values) => {
        Submit({values});        
      }}
    >
     {({values, setFieldValue}) => (
     <Form class = "form" >
        <h1>註冊</h1>
        <div>
          身份&nbsp;&nbsp;
          <div style={{color:"red"}}>
            <ErrorMessage class='errorMessage' name='role'/>
          </div>
          <Field class="form-select" name="role" as="select" disabled={lock}>
            <option>自然人</option>
            <option>法人</option>
          </Field>
        </div>
          <br/>
        <div>
          姓名/公司名稱 &nbsp;&nbsp;
          <div style={{color:"red"}}>
            <ErrorMessage class='errorMessage' name='username' />
          </div>
          <Field class="form-control" name="username" type="text" disabled={lock}/>
        </div>
          <br />
        <div>
          生日&nbsp;&nbsp;
          <div style={{color:"red"}}>
            <ErrorMessage class='errorMessage' name='birth'/>
          </div>
          <Field class="form-control" name="birth" type="date"placeholder="若為法人則無" disabled={lock}/>
        </div>
          <br />
        <div>
          電話&nbsp;&nbsp;
          <div style={{color:"red"}}>
            <ErrorMessage class='errorMessage' name='phoneNumber'/>
          </div>
          <Field class="form-control" name="phoneNumber" type="text" disabled={lock}/> 
        </div>
          <br />
        <div>
          Email&nbsp;&nbsp;
          <div style={{color:"red"}}>
            <ErrorMessage class='errorMessage' name='email'/> 
          </div>
          <Field class="form-control" name="email" type="email" placeholder="name@example.com" disabled={lock}/>
        </div>
          <br/>
        <div>
          頭貼(可選)&nbsp;&nbsp;
          <input class="form-control" name="file" type="file" accept=".jpeg,.pdf,.png" disabled={lock} onChange={(event) => {
            setFieldValue("file", event.currentTarget.files[0]);
          }} />
          <br/>
          { values.file && <PreviewImage file={ values.file }/>}
        </div>
        <div>
          密碼&nbsp;&nbsp;
          <div style={{color:"red"}}>
            <ErrorMessage class='errorMessage' name='password'/> 
          </div>
          <Field class="form-control" name="password" type="password" disabled={lock}/>
        </div>
        <br />
        <div>
          再次確認密碼&nbsp;&nbsp;
          <div style={{color:"red"}}>
            <ErrorMessage class='errorMessage' name='passwordConfirm'/> 
          </div>
          <Field class="form-control" name="passwordConfirm" type="password" disabled={lock}/>
        </div>
        <br />
        {lock === false && <button className="btn btn-primary" onClick={() => {setLock(true)}}>註冊</button>}
        {lock === true && <button className="btn btn-secondary" onClick={() => {setLock(false)}}>修改</button>}
        {lock === true && <button className="btn btn-secondary" type='submit'>確認</button>}
      </Form>)}
    </Formik>

    </div>

  );
};

export default RegisterPage;
